import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';

export class WoltApiService extends ApiService<any> {
  constructor() {
    super('wolt');
  }

  public getSyncJobs(params: any): AxiosPromise<any> {
    return axios.get(`${this.getBaseUrl()}/jobs`, {
      params,
    });
  }

  public getStatus(payload: { venue: string }) {
    return axios.get(`${this.getBaseUrl()}/venues/${payload.venue}/status`);
  }

  public syncFoodcard(payload: { venue: string }): AxiosPromise<void> {
    return axios.put(`${this.getBaseUrl()}/foodcards/venues/${payload.venue}`);
  }

  public online(payload: { venue: string }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venues/${payload.venue}/status/online`);
  }

  public offline(payload: { venue: string }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venues/${payload.venue}/status/offline`);
  }

  public updateOpeningHours(payload: { venue: string }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venues/${payload.venue}/opening-hours`);
  }
}
