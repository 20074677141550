export enum Country {
  AU = 'au',
  DE = 'de',
  FR = 'fr',
  LU = 'lu',
  CH = 'ch',
  ES = 'es',
  IS = 'is',
  IC = 'ic',
  PT = 'pt',
  CZ = 'cz',
}
