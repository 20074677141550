
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VWrapper from '@/components/shared/VWrapper.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import UiComponentTable from '@/components/ui/UiComponentTable.vue';
import { namespace } from 'vuex-class';
import Filter from '@/interfaces/api/Filter';
import CustomerGroup from '@/mixins/CustomerGroup';
import { UiComponent as UiComponentModel } from '@/interfaces/models/UiComponent';

const uiComponent = namespace('uiComponent');

@Component({
  components: { VWrapper, VEmptyState, UiComponentTable },
})
export default class UiSlider extends mixins(CustomerGroup) {
  @uiComponent.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @uiComponent.Action('fetch') public getItems!: any;
  @uiComponent.Action('delete') public deleteSlider!: any;

  @uiComponent.State((state) => state.pagination.total) public total!: number;
  @uiComponent.State('filter') public filter!: Filter;
  @uiComponent.State('items') public items!: [];

  public async mounted() {
    this.$startLoading('ui.slider');
    await this.setFilter({});
    await this.getItems({ type: 'slider', page: 1 });
    this.$stopLoading('ui.slider');
  }

  public async onFilter(val: object) {
    this.$startLoading('ui.slider');
    this.setFilter(val);
    await this.getItems({ type: 'slider', page: 1 });
    this.$stopLoading('ui.slider');
  }

  public async remove(uiComponent: UiComponentModel) {
    this.$startLoading('ui.slider');
    await this.deleteSlider({ type: 'slider', id: uiComponent._id });
    this.$stopLoading('ui.slider');
  }

  public edit(item: UiComponentModel) {
    this.$router.push({
      name: 'ui.slider.edit',
      params: { id: item._id },
      query: { ...this.$route.query },
    });
  }
}
