
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Category } from '@/interfaces/models/Category';
import { StockManagementType } from '@/enums/StockManagementType';

interface Selection {
  search?: string;
  type?: string;
  visibility?: string;
  categories?: string[];
}

enum StatusFormFilter {
  ALL = '',
  ACTIVE = 'visible',
  NOT_ACTIVE = 'not_visible',
}

@Component
export default class ArticleManagerFilter extends Vue {
  @Prop({ type: Array, required: true }) public categoryList!: Category[];

  public search: string = '';
  public type: string = '';
  public visibility: string = '';
  public categories: string[] = [];

  public emitSearch() {
    if (this.selection?.search?.length) {
      this.$emit('change', this.selection);
    }
  }

  get selection() {
    const selection: Selection = {};
    if (this.search !== '') {
      selection.search = this.search;
    }
    if (this.type !== '') {
      selection.type = this.type;
    }
    if (this.visibility !== '') {
      selection.visibility = this.visibility;
    }
    if (this.categories.length > 0) {
      selection.categories = this.categories;
    }

    return selection;
  }

  get statusArticle() {
    return [
      {
        value: StatusFormFilter.ACTIVE,
        text: this.$t('articleManager.filter.active'),
      },
      {
        value: StatusFormFilter.NOT_ACTIVE,
        text: this.$t('articleManager.filter.notActive'),
      },
    ];
  }

  get typeArticle() {
    return [
      { text: this.$t('articleManager.filter.article'), value: StockManagementType.ARTICLES },
      { text: this.$t('articleManager.filter.options'), value: StockManagementType.OPTION_ARTICLES },
    ];
  }

  public reset() {
    this.search = '';
    this.type = '';
    this.visibility = '';
    this.categories = [];
  }
}
