import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import Filter from '@/interfaces/api/Filter';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { SalesOverview } from '@/interfaces/models/SalesOverview';
import OrderServiceApi from '@/api/http/OrderServiceApi';

const api: OrderServiceApi = new OrderServiceApi();

interface SalesOverviewState {
  items: SalesOverview[];
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: SalesOverviewState = {
  pagination: {
    total: 0,
  },
  filter: {},
  items: [],
};

const actions: ActionTree<SalesOverviewState, RootState> = {
  fetch({ commit, state }) {
    return api.getSales({ ...state.filter }).then((res: AxiosResponse<SalesOverview[] | Pagination<SalesOverview>>) => {
      commit('setItems', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  reset({ commit }) {
    commit('reset');
  },
};

const mutations: MutationTree<SalesOverviewState> = {
  setItems(state: SalesOverviewState, data: Pagination<SalesOverview>) {
    state.items = (data.data as SalesOverview[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: SalesOverviewState, data: Filter) {
    state.filter = data;
  },
  reset(state: SalesOverviewState) {
    state.items = [];
    state.filter = {};
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
};
