import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';

export class UberEatsApiService extends ApiService<any> {
  constructor() {
    super('uber/eats');
  }

  public getSyncJobs(params: any) {
    return axios.get(`${this.getBaseUrl()}/jobs`, {
      params,
    });
  }

  public getStatus(payload: { venue: string }) {
    return axios.get(`${this.getBaseUrl()}/venues/${payload.venue}/status`);
  }

  public syncFoodcard(payload: { venue: string }): AxiosPromise<void> {
    return axios.put(`${this.getBaseUrl()}/menus/venues/${payload.venue}`);
  }
}
