import { ExternalOrderProvider } from '@/enums/ExternalOrderProvider';

export default [
  {
    text: 'article.table.name',
    value: 'name',
    default: true,
    editable: true,
    defaultOnMobile: true,
  },
  {
    text: 'article.table.tags',
    value: 'tags',
    default: false,
    editable: true,
    type: 'slot',
  },
  {
    text: 'Category',
    value: 'category',
    default: false,
    type: 'slot',
  },
  {
    text: 'article.table.internalName',
    value: 'internalName',
    default: false,
  },
  {
    text: 'article.table.description',
    value: 'description',
    default: false,
    editable: true,
  },
  {
    text: 'article.table.internalNote',
    value: 'internalNote',
    default: true,
    editable: true,
  },
  {
    text: 'article.table.number',
    value: 'number',
    default: false,
    editable: true,
  },
  {
    text: 'article.table.externalId',
    value: 'externalId',
    default: false,
    editable: true,
  },
  {
    text: 'article.table.gtin',
    value: 'gtin',
    default: false,
    editable: true,
  },
  {
    text: 'article.table.stock',
    value: 'stockAmount',
    default: false,
  },
  {
    text: 'article.table.assets',
    value: 'assets',
    type: 'asset',
    default: false,
  },
  {
    text: 'article.table.icon',
    value: 'icon',
    type: 'asset',
    default: false,
  },
  {
    text: 'article.table.banner',
    value: 'banner',
    type: 'asset',
    default: false,
  },
  {
    text: 'article.table.price',
    value: 'price',
    type: 'decimal',
    default: true,
    editable: true,
    defaultOnMobile: true,
  },
  {
    text: 'article.table.preorderDeliveryPrice',
    value: 'priceByType.preorder.delivery',
    default: false,
    type: 'priceByType',
    virtual: true,
    editable: true,
  },
  {
    text: 'article.table.preorderTakeAwayPrice',
    value: 'priceByType.preorder.takeAway',
    default: false,
    type: 'priceByType',
    virtual: true,
    editable: true,
  },
  {
    text: 'article.table.taxInside',
    value: 'taxInside',
    type: 'slot',
    default: false,
    editable: true,
  },
  {
    text: 'article.table.taxTakeaway',
    value: 'taxTakeaway',
    type: 'slot',
    default: false,
    editable: true,
  },
  {
    text: 'article.table.jet',
    value: ExternalOrderProvider.JET,
    default: false,
    type: 'externalOrderPrice',
    virtual: true,
    editable: true,
  },
  {
    text: 'article.table.uber',
    value: ExternalOrderProvider.UBER_EATS,
    default: false,
    type: 'externalOrderPrice',
    virtual: true,
    editable: true,
  },
  {
    text: 'article.table.wolt',
    value: ExternalOrderProvider.WOLT,
    default: false,
    type: 'externalOrderPrice',
    virtual: true,
    editable: true,
  },
  {
    text: 'article.table.uberEatsTakeawayEnabled',
    value: 'uberEatsTakeawayEnabled',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.uberEatsDeliveryEnabled',
    value: 'uberEatsDeliveryEnabled',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.woltVisibility',
    value: 'woltVisibility',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.woltActive',
    value: 'woltActive',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.jetTakeawayEnabled',
    value: 'jetTakeawayEnabled',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.jetDeliveryEnabled',
    value: 'jetDeliveryEnabled',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.visible',
    value: 'visible',
    default: true,
    type: 'slot',
    defaultOnMobile: true,
  },
  {
    text: 'article.table.isControlArticle',
    value: 'isControlArticle',
    default: true,
    type: 'slot',
    defaultOnMobile: true,
  },
  {
    text: 'article.table.sortOrder',
    value: 'sortOrder',
    default: false,
    editable: true,
  },
  {
    text: 'article.table.active',
    value: 'isActive',
    default: false,
    type: 'slot',
  },
];
