import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/stores/auth';
import app from '@/stores/app';
import venue from '@/stores/venue';
import venueTransactions from '@/stores/venueTransactions';
import venueLabel from '@/stores/venueLabel';
import dialog from '@/stores/dialog';
import foodcard from '@/stores/foodcard';
import notification from '@/stores/notification';
import statistics from '@/stores/statistics';
import sales from '@/stores/sales';
import analytics from '@/stores/analytics';
import workflow from '@/stores/workflow';
import customer from '@/stores/customer';
import printer from '@/stores/printer';
import role from '@/stores/role';
import user from '@/stores/user';
import table from '@/stores/table';
import tag from '@/stores/tag';
import constrain from '@/stores/constrain';
import payment from '@/stores/payment';
import download from '@/stores/download';
import maintenance from '@/stores/maintenance';
import promo from '@/stores/promo';
import coupon from '@/stores/coupon';
import receipt from '@/stores/receipt';
import provision from '@/stores/provision';
import client from '@/stores/client';
import information from '@/stores/information';
import cartRecommendation from '@/stores/cartRecommendation';
import screensaver from '@/stores/screensaver';
import banner from '@/stores/banner';
import printGroups from '@/stores/printGroups';
import membership from '@/stores/membership';
import eposJobs from '@/stores/eposJobs';
import loyalty from '@/stores/loyalty';
import automaticReport from '@/stores/automaticReport';
import reward from '@/stores/reward';
import customerGroupsProperty from '@/stores/customerGroupProprty';
import sanifairLogs from './sanifairLogs';
import cupCode from './cupCode';
import sync from './sync';
import articleManager from './articleManager';
import aggregatorSyncs from './aggregatorSyncs';
import inventory from './inventory';
import company from './company';
import salesOverview from './salesOverview';
import uiComponent from './uiComponent';
import mfa from './mfa';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    client,
    user,
    auth,
    printer,
    payment,
    table,
    venue,
    venueTransactions,
    venueLabel,
    provision,
    banner,
    role,
    workflow,
    notification,
    dialog,
    tag,
    constrain,
    printGroups,
    receipt,
    foodcard,
    statistics,
    screensaver,
    sales,
    analytics,
    customer,
    promo,
    coupon,
    information,
    download,
    maintenance,
    cartRecommendation,
    membership,
    eposJobs,
    loyalty,
    automaticReport,
    reward,
    customerGroupsProperty,
    sanifairLogs,
    cupCode,
    sync,
    articleManager,
    aggregatorSyncs,
    inventory,
    company,
    salesOverview,
    uiComponent,
    mfa,
  },
  strict: true,
});
