
import { Component, Watch, Vue } from 'vue-property-decorator';
import Toolbar from '@/components/layout/Toolbar.vue';
import Sidebar from '@/components/layout/Sidebar.vue';
import { namespace } from 'vuex-class';
import BreadCrumb from '@/components/layout/BreadCrumb.vue';
import { Venue } from '@/interfaces/models/Venue';
import VenueSelectorDialog from '@/components/layout/VenueSelectorDialog.vue';
import MFADialog from '@/components/layout/MFADialog.vue';
import { DialogType } from '@/enums/DialogType';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import NotificationDialog from '@/components/layout/NotificationDialog.vue';
import Navbar from '@/components/layout/Navbar.vue';
import DownloadHandler from '@/components/download/DownloadHandler.vue';
import LanguagePicker from '@/components/layout/LanguagePicker.vue';
import cookie from 'js-cookie';
import moment from 'moment';

const auth = namespace('auth');
const venue = namespace('venue');
const dialog = namespace('dialog');
const app = namespace('app');
const maintenance = namespace('maintenance');
const foodcard = namespace('foodcard');
const mfa = namespace('mfa');

@Component({
  components: {
    LanguagePicker,
    DownloadHandler,
    Navbar,
    NotificationDialog,
    Notification,
    VenueSelectorDialog,
    BreadCrumb,
    Sidebar,
    Toolbar,
    MFADialog,
  },
})
export default class App extends mixins(Notification) {
  @dialog.State('venueSelector') public venueSelectorVisible!: boolean;
  @dialog.Action('hide') public hideDialog!: any;

  @maintenance.State('active') public maintenance!: boolean;
  @maintenance.Action('getStatus') public getMaintenanceStatus!: any;

  @app.State('venues') protected venues!: Venue[];
  @app.Action('fetchVenues') protected getVenues!: any;

  @venue.State('active') protected activeVenue!: Venue;
  @venue.State('needsRefresh') protected venueNeedsRefresh!: boolean;
  @venue.Action('show') protected showVenue!: any;
  @venue.Action('refreshed') protected venueIsRefreshed!: any;

  @auth.State('authorized') protected isAuthorized!: boolean;
  @auth.Action('logout') protected logout!: any;

  @foodcard.Action('resetArticles') protected resetArticles!: any;
  @foodcard.Action('resetOptions') protected resetOptions!: any;

  @mfa.State('action') mfaAction!: any;
  @mfa.State('mfaModalVisible') mfaModalVisible!: any;
  @mfa.Action('closeMFAModal') closeMFAModal!: any;

  public drawer: boolean = true;

  get isFullHeight() {
    if (this.$route.name) {
      return (
        this.$route.name!.includes('register') ||
        this.$route.name!.includes('forgotPassword') ||
        this.$route.name!.includes('resetPassword')
      );
    }

    return false;
  }

  get version() {
    // @ts-ignore
    return VUE_APP_VERSION;
  }

  get isEditingRoute() {
    return this.$route!.name!.includes('edit') || this.$route!.name!.includes('create');
  }

  get isVenueRoute() {
    return this.$route.name!.includes('venue');
  }

  get isPanicActive() {
    if (!this.activeVenue?.panicEndAt) return false;
    return moment(this.activeVenue?.panicEndAt).isSameOrAfter(moment());
  }

  public created() {
    this.getMaintenanceStatus();
    // @ts-ignore
    // console.info(`Version: ${VUE_APP_VERSION}`);
  }

  public doLogout() {
    this.logout();
    this.$nextTick(() => {
      // @ts-ignore
      this.$router.push({ path: '/' });
      this.notifySuccess('auth.notification.logout');
    });
  }

  public async selectVenue(venue: Venue | string) {
    this.resetArticles();
    this.resetOptions();
    const v: Partial<Venue> = typeof venue === 'string' ? { _id: venue } : venue;
    this.$startLoading('venue');
    if (this.isEditingRoute && ((this.activeVenue && v._id !== this.activeVenue._id) || !this.activeVenue)) {
      const parts: string[] = this.$route.name!.split('.');
      this.$router.push({ name: `${parts[0]}.index` });
    }
    await this.showVenue({ id: v._id });
    this.hideDialog(DialogType.VenueSelector);
    this.$stopLoading('venue');
  }

  public hideVenueSelector() {
    if (this.activeVenue) {
      this.hideDialog(DialogType.VenueSelector);
    }
  }

  public forceHideVenueSelector() {
    this.hideDialog(DialogType.VenueSelector);
  }

  public async mounted() {
    if (this.isAuthorized) {
      this.$startLoading('venue');
      this.$startLoading('venue.app');
      await this.getVenues();
      const v: string | undefined = cookie.get('venue');
      if (v) {
        await this.selectVenue(v);
      }
      this.$stopLoading('venue');
      this.$stopLoading('venue.app');
    }
  }

  public formatPanicEndAt(panicEndAt: string) {
    return Vue.filter('moment')(panicEndAt, 'DD.MM.YYYY HH:mm');
  }

  @Watch('venueNeedsRefresh')
  public async onVenueNeedsRefresh() {
    if (this.activeVenue && this.activeVenue._id && this.venueNeedsRefresh) {
      this.$startLoading('venue');
      await this.showVenue({ id: this.activeVenue._id });
      this.venueIsRefreshed();
      this.$stopLoading('venue');
    }
  }

  @Watch('isAuthorized')
  public async onVenueChange() {
    if (this.isAuthorized && this.venues.length === 0) {
      this.$startLoading('venue');
      this.$startLoading('venue.app');
      await this.getVenues();
      this.$stopLoading('venue');
      this.$stopLoading('venue.app');
    }
  }
}
