
import { UberEatsApiService } from '@/api/http/UberEatsApiService';
import { WoltApiService } from '@/api/http/WoltApiService';
import { Permission } from '@/enums/Permission';
import { Venue } from '@/interfaces/models/Venue';
import { AxiosResponse } from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';

const woltApiService: WoltApiService = new WoltApiService();
const uberApiService: UberEatsApiService = new UberEatsApiService();

@Component
export default class ExternalOrderProviderStatus extends Vue {
  @Prop({ type: Object, required: true }) public venue!: Venue;

  public woltEnabled: boolean = false;
  public uberEnabled: boolean = false;
  public isActiveWolt: boolean = false;
  public isActiveUber: boolean = false;

  public mounted() {
    if (!this.venue?.externalOrderProviders) return;
    const { uberEats, wolt } = this.venue.externalOrderProviders;

    if (uberEats?.enabled && this.$can(Permission.UBER_EATS_VENUE_STATUS)) {
      this.uberEnabled = uberEats.enabled;
      this.getStatusOfUber();
    }

    if (wolt?.enabled && this.$can(Permission.WOLT_VENUE_STATUS)) {
      this.woltEnabled = wolt.enabled;
      this.getStatusOfWolt();
    }
  }

  public get woltStatusText() {
    return this.isActiveWolt ? 'Online' : 'Offline';
  }

  public get uberStatusText() {
    return this.isActiveUber ? 'Online' : 'Offline';
  }

  public getStatusOfUber() {
    uberApiService.getStatus({ venue: this.venue._id }).then((res: AxiosResponse) => {
      this.isActiveUber = res.data?.isActive;
    });
  }

  public getStatusOfWolt() {
    woltApiService.getStatus({ venue: this.venue._id }).then((res: AxiosResponse) => {
      this.isActiveWolt = res.data?.isActive;
    });
  }
}
