
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ValidationObserver } from 'vee-validate';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import VLanguageField from '@/components/shared/form/VLanguageField.vue';
import { InputType } from '@/enums/InputType';
import { UiComponentType } from '@/enums/UiComponentType';
import { Slide } from '@/interfaces/models/Slide';
import StackedForm from '../../mixins/StackedForm';
import CustomerGroup from '@/mixins/CustomerGroup';
import UiComponentPrimaryClickActions from '@/mixins/UiComponentPrimaryClickActions';
import { cloneDeep } from '@/util/helper';
import { FormItem } from '@/interfaces/components/FormItem';

@Component({
  components: { VFormBuilder, VLanguageField },
})
export default class UiComponentForm extends mixins(StackedForm, CustomerGroup, UiComponentPrimaryClickActions) {
  @Prop({ type: Boolean, default: false }) public editing!: boolean;
  @Prop({ type: Boolean, default: false }) public showSlides!: boolean;
  @Prop({ type: Boolean, default: false }) public showRichText!: boolean;
  @Prop({ type: String, required: true }) public type!: UiComponentType;

  public showComponentAction: boolean = false;
  public slides: Slide[] = [];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
    clickActionForm: InstanceType<typeof VFormBuilder> & { form: any };
    uiComponentObserver: InstanceType<typeof ValidationObserver>;
  };

  get primaryClickActionValues() {
    return (this.initialValues as any)?.primaryClickAction;
  }

  get items() {
    return [
      {
        name: 'customerGroup',
        type: InputType.Autocomplete,
        label: 'ui.form.customerGroup',
        items: this.customerGroups,
        multiple: false,
        rules: 'required',
        disabled: this.editing,
      },
      ...(!this.showSlides
        ? [{ name: 'title', type: InputType.Language, label: 'ui.form.title', rules: 'required' }]
        : []),
      {
        name: 'identifier',
        type: InputType.Text,
        label: 'ui.form.identifier',
        rules: 'required|identifier',
        disabled: this.editing,
      },
      ...(!this.showSlides
        ? [
            {
              name: 'textColor',
              type: InputType.ColorPicker,
              label: 'ui.form.textColor',
              mode: 'hexa',
              hideModeSwitch: true,
              default: '#FFFFFF00',
            },
            {
              name: 'backgroundColor',
              type: InputType.ColorPicker,
              label: 'ui.form.backgroundColor',
              mode: 'hexa',
              hideModeSwitch: true,
              default: '#FFFFFF00',
            },
            {
              name: 'backgroundImage',
              type: InputType.File,
              label: 'ui.form.backgroundImage',
              accept: 'image/*',
              visible: this.type === UiComponentType.BANNER,
            },
            {
              name: 'htmlContent',
              type: InputType.Language,
              useRichText: true,
              open: true,
              label: 'ui.form.htmlContent',
              visible: this.showRichText,
            },
            {
              name: 'primaryClickAction',
              default: { type: 'link', value: '', label: { en: '', de: '' } },
            },
          ]
        : []),
    ];
  }

  get clickActionItems() {
    return [
      {
        name: 'type',
        type: InputType.Autocomplete,
        label: 'ui.form.actionType',
        items: this.uiComponentPrimaryClickActions,
        default: 'link',
        multiple: false,
      },
      { name: 'value', type: InputType.Text, label: 'ui.form.value', rules: 'required|url_simple' },
      { name: 'label', type: InputType.Language, label: 'ui.form.label', rules: 'required' },
    ];
  }

  public mounted() {
    const initialValues = { ...cloneDeep(this.initialValues as any) };

    if (this.primaryClickActionValues) {
      this.showComponentAction = true;
    }

    if (this.showSlides && initialValues?.slides?.length) {
      for (const slide of initialValues.slides) {
        this.slides.push({
          ...slide,
          primaryClickAction: slide.primaryClickAction || undefined,
          backgroundColor: slide.backgroundColor || '#FFFFFF00',
          textColor: slide.textColor || '#FFFFFF00',
        });
      }
    }
  }

  public addSlide() {
    this.slides.push({
      backgroundImage: undefined,
      backgroundColor: '#FFFFFF00',
      textColor: '#FFFFFF00',
      htmlContent: { en: '', de: '' },
      file: undefined,
      primaryClickAction: undefined,
    });
  }

  public removeSlide(item: Slide) {
    this.slides.splice(this.slides.indexOf(item), 1);
  }

  public removeSlideImage(index: number) {
    if (!this.slides?.[index]) {
      return;
    }

    this.slides[index].backgroundImage = '';
  }

  public setSlideAction(index: number) {
    const slide = this.slides[index];

    if (!slide) return;

    if (slide.primaryClickAction) {
      this.slides[index].primaryClickAction = undefined;
    } else {
      this.slides[index].primaryClickAction = { type: 'link', value: '', label: { en: '', de: '' } };
    }
  }

  public formDataAppendObject(fd: FormData, obj: { [key: string]: any } = {}, key?: string) {
    let i;
    let k;

    for (i in obj) {
      k = key ? key + '[' + i + ']' : i;

      if (obj[i] instanceof File) {
        fd.append(k, obj[i]);
      } else if (typeof obj[i] == 'object') {
        this.formDataAppendObject(fd, obj[i], k);
      } else {
        fd.append(k, obj[i]);
      }
    }
  }

  public removeImage(item: FormItem) {
    this.$refs.form?.setValue(item.name, '');
  }

  public getData() {
    const formData: FormData = new FormData();

    const data = {
      ...this.$refs.form.getData(),
      ...(this.showSlides && this.slides?.length
        ? {
            slides: this.slides.map((slide: Slide) => ({
              ...slide,
              primaryClickAction: slide.primaryClickAction || {},
              textColor: !!slide.textColor && slide.textColor !== '#FFFFFF00' ? slide.textColor : '',
              backgroundColor:
                !!slide.backgroundColor && slide.backgroundColor !== '#FFFFFF00' ? slide.backgroundColor : '',
            })),
          }
        : {}),
      primaryClickAction: this.showComponentAction ? this.$refs.clickActionForm.form : {},
      component: this.type,
    };

    if (!this.showRichText) {
      delete data.htmlContent;
    }

    if (!data.backgroundColor || data.backgroundColor === '#FFFFFF00') {
      delete data.backgroundColor;
    }

    if (!data.textColor || data.textColor === '#FFFFFF00') {
      delete data.textColor;
    }

    if (data.slides?.length) {
      for (const slide of data.slides) {
        if (!slide.backgroundColor) {
          delete slide.backgroundColor;
        }

        if (!slide.textColor) {
          delete slide.textColor;
        }
      }
    }

    this.formDataAppendObject(formData, data);

    return formData;
  }
}
