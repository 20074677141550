export default [
  {
    text: 'tag.form.identifier',
    value: 'identifier',
  },
  {
    text: 'information.table.title',
    value: 'title',
  },
  {
    text: 'user.form.customerGroup',
    value: 'customerGroup',
  },
  {
    text: 'article.fontColor',
    value: 'textColor',
    type: 'slot',
  },
  {
    text: 'article.backgroundColor',
    value: 'backgroundColor',
    type: 'slot',
  },
  {
    text: 'sync.props.optionGroup.backgroundImage',
    value: 'backgroundImage',
    type: 'slot',
  },
  {
    text: 'information.form.action',
    value: 'primaryClickAction',
    type: 'slot',
  },
  {
    text: 'nav.uiSlides',
    value: 'slides',
    type: 'slot',
  },
  {
    text: 'sync.created',
    value: 'createdAt',
    type: 'date',
    format: 'DD.MM.YYYY HH:mm',
  },
  {
    text: 'sync.updated',
    value: 'updatedAt',
    type: 'date',
    format: 'DD.MM.YYYY HH:mm',
  },
];
