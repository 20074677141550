import { ExternalOrderProvider } from '@/enums/ExternalOrderProvider';

export default [
  {
    text: 'option.articles.table.name',
    value: 'name',
    default: true,
    editable: true,
    defaultOnMobile: true,
  },
  {
    text: 'article.table.tags',
    value: 'tags',
    default: false,
    editable: true,
    type: 'slot',
  },
  {
    text: 'option.articles.table.description',
    value: 'description',
    default: true,
    editable: true,
  },
  {
    text: 'article.table.externalId',
    value: 'externalId',
    default: true,
    editable: true,
  },
  {
    text: 'article.table.gtin',
    value: 'gtin',
    default: false,
    editable: true,
  },
  {
    text: 'option.articles.table.internalNote',
    value: 'internalNote',
    default: true,
  },
  {
    text: 'option.articles.table.number',
    value: 'optionNumber',
    default: true,
    editable: true,
  },
  {
    text: 'option.articles.table.assets',
    value: 'assets',
    type: 'asset',
    default: true,
  },
  {
    text: 'option.articles.table.price',
    value: 'price',
    type: 'decimal',
    default: true,
    editable: true,
    defaultOnMobile: true,
  },
  {
    text: 'article.table.taxInside',
    value: 'taxInside',
    type: 'slot',
    default: false,
    editable: true,
  },
  {
    text: 'article.table.taxTakeaway',
    value: 'taxTakeaway',
    type: 'slot',
    default: false,
    editable: true,
  },
  {
    text: 'article.table.jet',
    value: ExternalOrderProvider.JET,
    default: false,
    type: 'externalOrderPrice',
    virtual: true,
    editable: true,
  },
  {
    text: 'article.table.uber',
    value: ExternalOrderProvider.UBER_EATS,
    default: false,
    type: 'externalOrderPrice',
    virtual: true,
    editable: true,
  },
  {
    text: 'article.table.wolt',
    value: ExternalOrderProvider.WOLT,
    default: false,
    type: 'externalOrderPrice',
    virtual: true,
    editable: true,
  },
  {
    text: 'article.table.uberEatsTakeawayEnabled',
    value: 'uberEatsTakeawayEnabled',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.uberEatsDeliveryEnabled',
    value: 'uberEatsDeliveryEnabled',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.woltVisibility',
    value: 'woltVisibility',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.woltActive',
    value: 'woltActive',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.jetTakeawayEnabled',
    value: 'jetTakeawayEnabled',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'article.table.jetDeliveryEnabled',
    value: 'jetDeliveryEnabled',
    default: false,
    type: 'slot',
    virtual: true,
    defaultOnMobile: false,
  },
  {
    text: 'option.articles.table.sortOrder',
    value: 'sortOrder',
    default: false,
    editable: true,
  },
  {
    text: 'option.articles.table.visible',
    value: 'visible',
    type: 'slot',
    default: true,
    defaultOnMobile: true,
  },
  {
    text: 'option.articles.table.active',
    value: 'isActive',
    type: 'slot',
    default: true,
  },
];
