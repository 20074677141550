import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import { Company } from '@/interfaces/models/Company';
import { CompanyApiService } from '@/api/http/CompanyApiService';

interface CompanyState {
  items: Company[];
  active: Company | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: CompanyApiService = new CompanyApiService();

const state: CompanyState = {
  active: null,
  filter: {},
  items: [],
  pagination: { total: 0 },
};

const actions: ActionTree<CompanyState, RootState> = {
  fetch({ commit, state }) {
    return api.getCompanies({ params: state.filter }).then((res: AxiosResponse<Company[] | Pagination<Company>>) => {
      commit('setItems', res.data);
    });
  },
  getCompaniesByVenue({ commit, state }, data: { id: string }) {
    return api.getCompaniesByVenue({ id: data.id, params: state.filter }).then((res: AxiosResponse<Company>) => {
      commit('setItems', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<Company>) => {
      commit('setActive', res.data);
    });
  },
  update({}, data: Partial<Company>) {
    return api.update(data);
  },
  destroy({ commit }, data: { id: string }) {
    return api.destroyCompany(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  store({ commit }, data: Partial<Company>) {
    return api.storeCompany(data).then((res: AxiosResponse<Company>) => {
      commit('addItem', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  setActive({ commit }, data: Company) {
    commit('setActive', data);
  },
};

const mutations: MutationTree<CompanyState> = {
  setItems(state: CompanyState, data: Pagination<Company>) {
    state.items = (data.data as Company[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  addItem(state: CompanyState, data: Company) {
    state.items.push(data);
  },
  setActive(state: CompanyState, data: Company | null) {
    state.active = data;
  },
  setFilter(state: CompanyState, data: Filter) {
    state.filter = data;
  },
  removeItem(state: CompanyState, data: { _id: string }) {
    state.items = state.items.filter((item: Company) => item._id !== data._id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
