
import { Component, Vue, Prop } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Permission } from '@/enums/Permission';
import { AxiosResponse } from 'axios';
import DataApiService from '@/api/http/DataApiService';
import { Venue } from '@/interfaces/models/Venue';
import TranslationApiService from '@/api/http/TranslationApiService';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';

const venue = namespace('venue');

@Component({
  components: { VWrapper },
})
export default class Translation extends mixins(Notification) {
  @venue.State('active') public venue!: Venue;

  public data() {
    return {
      sourceLang: undefined,
      targetLangs: [],
      languages: ['en', 'de', 'fr', 'nl', 'it', 'pl', 'es', 'ru', 'da', 'ja', 'tr', 'is', 'pt', 'cs'],
    };
  }

  public async translate() {
    const api = new TranslationApiService();
    this.$startLoading('translation');
    this.notifyInfo('translation.start');

    // @ts-ignore
    const res: AxiosResponse<any> = await api.translateFoodcard(this.venue._id, this.sourceLang, this.targetLangs);

    this.$stopLoading('translation');
  }
}
