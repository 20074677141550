
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { DeliveryProviders } from '@/interfaces/models/DeliveryProviders';
import { Permission } from '@/enums/Permission';
import { Venue } from '@/interfaces/models/Venue';
import { UberDirectProvider } from '@/interfaces/models/UberDirectProvider';
import { OrderLordProvider } from '@/interfaces/models/orderLord/OrderLordProvider';
import { WoltDriveProvider } from '@/interfaces/models/woltDrive/WoltDriveProvider';

@Component({
  components: { VFormBuilder },
})
export default class DeliverProvidersForm extends mixins(StackedForm) {
  public $refs!: {
    smoothrDispatch: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
    orderLord: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
    rushHour: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
    uberDelivery: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
    woltDrive: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
  };

  get initValues() {
    const venue: Venue = this.initialValues as Venue;

    return venue?.deliveryProviders;
  }

  get initOrderLord() {
    const values: DeliveryProviders = { ...this.initValues } as DeliveryProviders;

    return {
      orderLordActive: !!(values.orderLord && values.orderLord.orderLordActive),
      orderLordEnabled: !!(values.orderLord && values.orderLord.orderLordEnabled),
      orderLordToken: values.orderLord && values.orderLord.orderLordToken,
      orderLordPassword: values.orderLord && values.orderLord.orderLordPassword,
    };
  }

  get initSmoothrDispatch() {
    const values: DeliveryProviders = { ...this.initValues } as DeliveryProviders;

    if (!values?.smoothrDispatch) {
      return { enabled: !!(this.initialValues as Venue)?.smoothrDispatchEnabled };
    }

    return { ...values.smoothrDispatch };
  }

  get initUberDelivery() {
    const values: DeliveryProviders = { ...this.initValues } as DeliveryProviders;

    if (!values?.uberDirect) {
      return {
        enabled: !!(this.initialValues as Venue)?.uberDeliveryEnabled,
        customerId: (this.initialValues as Venue)?.uberCustomerId,
      };
    }

    return { ...values.uberDirect };
  }

  get smoothrDispatchItems() {
    return [
      {
        name: 'enabled',
        type: InputType.Checkbox,
        default: null,
        label: 'venue.form.deliveryProviders.enabled',
      },
    ];
  }

  get orderLordItems() {
    return [
      {
        name: 'orderLordEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.deliveryProviders.orderLordEnabled',
        visible: this.$can(Permission.DELIVERY_PROVIDER_ENABLING),
      },
      {
        name: 'orderLordActive',
        type: InputType.Checkbox,
        label: 'venue.form.deliveryProviders.orderLordActive',
        visible: this.$can(Permission.DELIVERY_PROVIDER_ACTIVATION),
      },
      {
        name: 'orderLordToken',
        type: InputType.Text,
        label: 'venue.form.deliveryProviders.orderLordToken',
        rules: (form: OrderLordProvider) => (form.orderLordEnabled ? 'required' : ''),
      },
      {
        name: 'orderLordPassword',
        type: InputType.Text,
        label: 'venue.form.deliveryProviders.orderLordPassword',
        rules: (form: OrderLordProvider) => (form.orderLordEnabled ? 'required' : ''),
      },
    ];
  }

  get rushHourItems() {
    return [
      {
        name: 'rushhourIntegration',
        type: InputType.Checkbox,
        label: 'venue.form.deliveryProviders.rushhourIntegration',
        visible: this.$can(Permission.DELIVERY_PROVIDER_ACTIVATION),
      },
      { name: 'rushhourToken', type: InputType.Text, label: 'venue.form.deliveryProviders.rushhourToken' },
    ];
  }

  get woltDriveItems() {
    return [
      {
        name: 'enabled',
        type: InputType.Checkbox,
        label: 'venue.form.deliveryProviders.enabled',
      },
      {
        name: 'merchantId',
        type: InputType.Text,
        label: 'venue.form.deliveryProviders.woltdriveMerchantId',
        rules: (form: WoltDriveProvider) => (form.enabled ? 'required' : ''),
      },
      {
        name: 'merchantSecretToken',
        type: InputType.Text,
        label: 'venue.form.deliveryProviders.woltdriveToken',
        rules: (form: WoltDriveProvider) => (form.enabled ? 'required' : ''),
      },
    ];
  }

  get uberDeliveryItems() {
    return [
      {
        name: 'customerId',
        type: InputType.Text,
        label: 'venue.form.deliveryProviders.customerId',
        rules: (form: UberDirectProvider) => (form.enabled ? 'required' : ''),
      },
      {
        name: 'clientId',
        type: InputType.Text,
        label: 'venue.form.deliveryProviders.clientId',
        rules: (form: UberDirectProvider) => (form.enabled ? 'required' : ''),
      },
      {
        name: 'clientSecret',
        type: InputType.Text,
        label: 'venue.form.deliveryProviders.clientSecret',
        rules: (form: UberDirectProvider) => (form.enabled ? 'required' : ''),
      },
      {
        name: 'enabled',
        type: InputType.Checkbox,
        default: null,
        label: 'venue.form.deliveryProviders.enabled',
      },
      {
        name: 'batchSize',
        type: InputType.Text,
        default: 1,
        rules: 'integer|min:1',
        label: 'venue.form.deliveryProviders.batchSize',
      },
    ];
  }

  public getData() {
    return {
      smoothrDispatchEnabled: this.$refs?.smoothrDispatch?.form?.enabled,
      uberCustomerId: this.$refs?.uberDelivery?.form?.customerId,
      uberDeliveryEnabled: this.$refs?.uberDelivery?.form?.enabled,
      deliveryProviders: {
        useDeliveryProvider: this.useDeliveryProvider(),
        smoothrDispatch: {
          ...this.$refs?.smoothrDispatch?.form,
        },
        orderLord: {
          ...this.$refs.orderLord?.form,
        },
        rushHour: {
          ...this.$refs.rushHour?.form,
        },
        uberDirect: {
          ...this.$refs.uberDelivery?.form,
        },
        woltDrive: {
          ...this.$refs.woltDrive?.form,
        },
      },
    };
  }

  private useDeliveryProvider() {
    return (
      this.$refs?.orderLord?.form?.orderLordEnabled ||
      this.$refs?.rushHour?.form?.rushhourIntegration ||
      this.$refs.uberDelivery?.form?.enabled ||
      this.$refs?.woltDrive?.form?.enabled
    );
  }
}
