import { RootState } from '@/interfaces/stores/RootState';
import { ActionTree, MutationTree } from 'vuex';
import { Credentials } from '@/interfaces/auth/Credentials';
import { AxiosResponse } from 'axios';
import { AuthResponse } from '@/interfaces/auth/AuthResponse';
import cookie from 'js-cookie';
import AuthApiService from '@/api/http/AuthApiService';
import { User } from '@/interfaces/models/User';
import { getJoinedTokenChunk } from '@/util/helper';

const api: AuthApiService = new AuthApiService();

interface AuthState {
  authorized: boolean;
  user: Partial<User> | null;
}

const state: AuthState = {
  authorized: false,
  user: null,
};

const actions: ActionTree<AuthState, RootState> = {
  login({ commit, dispatch }, cred: Credentials) {
    return api.login(cred).then((response: AxiosResponse<AuthResponse>) => {
      const chunkSize = 1024;
      const numChunks = Math.ceil(response.data.token.length / chunkSize);

      for (let i = 0; i < numChunks; i++) {
        const chunk = response.data.token.substr(i * chunkSize, chunkSize);
        const cookieName = `tokenChunk${i}`;
        document.cookie = `${cookieName}=${encodeURIComponent(chunk)}; path=/`;
      }
      cookie.set('numTokenChunks', numChunks.toString(), { expires: 1 });

      cookie.set('token', response.data.token, { expires: 1 });
      cookie.set('roles', response.data.roles, { expires: 1 });
      cookie.set('role', response.data.role!, { expires: 1 });
      cookie.set('email', response.data.email, { expires: 1 });
      cookie.set('name', response.data.name, { expires: 1 });
      cookie.set('user', response.data.id, { expires: 1 });
      cookie.set('mfa', (response.data.mfa as string) || '', { expires: 1 });
      cookie.set('linkedCustomerGroup', response.data.linkedCustomerGroup || '', { expires: 1 });
      cookie.set('customerGroup', response.data.customerGroup || '', { expires: 1 });
      cookie.set('permissions', response.data.permissions!, { expires: 1 });

      if (response.data.venues) {
        localStorage.setItem('venues', JSON.stringify(response.data.venues));
      } else {
        localStorage.setItem('venues', JSON.stringify([response.data.venue]));
      }
      commit('login', response.data);
      dispatch('app/filterVenues', null, { root: true });
    });
  },
  checkAuth({ commit, dispatch }) {
    let token: string = cookie.get('token')!;

    // todo - if token the bigger than 4kb
    if (!token && cookie.get('numTokenChunks')) {
      token = getJoinedTokenChunk();
    }
    if (token) {
      dispatch('app/filterVenues', null, { root: true });
      commit('authenticate', {
        email: cookie.get('email'),
        name: cookie.get('name'),
        id: cookie.get('user'),
        linkedCustomerGroup: cookie.get('linkedCustomerGroup'),
        customerGroup: cookie.get('customerGroup'),
      });
    }
  },
  logout({ commit }) {
    cookie.remove('venues');
    cookie.remove('permissions');
    cookie.remove('role');
    cookie.remove('roles');
    cookie.remove('token');
    cookie.remove('email');
    cookie.remove('user');
    cookie.remove('mfa');
    cookie.remove('name');
    cookie.remove('customerGroup');
    cookie.remove('linkedCustomerGroup');
    cookie.remove('venue');
    if (cookie.get('numTokenChunks')) {
      const numChunks = Number(cookie.get('numTokenChunks'));

      for (let i = 0; i < numChunks; i++) {
        cookie.remove(`tokenChunk${i}`);
      }
      cookie.remove('numTokenChunks');
    }
    commit('logout');
  },
};

const mutations: MutationTree<AuthState> = {
  login(state: AuthState, data: AuthResponse) {
    state.authorized = true;
    state.user = {
      email: data.email,
      id: data.id,
      name: data.name,
      customerGroup: data.customerGroup,
      linkedCustomerGroup: data.linkedCustomerGroup,
    };
  },
  authenticate(state: AuthState, user: Partial<User>) {
    state.authorized = true;
    state.user = user;
  },
  logout(state: AuthState) {
    state.authorized = false;
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
