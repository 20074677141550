
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import UiComponentFilter from '@/components/ui/UiComponentFilter.vue';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { UiComponent } from '@/interfaces/models/UiComponent';
import { CustomerGroup as CustomerGroupEnum } from '@/enums/CustomerGroup';

@Component({
  components: { VTable, VEmptyState, UiComponentFilter },
})
export default class UiComponentTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: UiComponent[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Array, required: true }) public customerGroups!: CustomerGroupEnum[];
  @Prop({ type: Boolean, required: true }) public canEdit!: boolean;
  @Prop({ type: Boolean, required: true }) public canDelete!: boolean;
  @Prop({ type: Boolean, default: false }) public isSlider!: boolean;

  public headers: ColumnHeader[] = headers;

  get availableHeaders() {
    if (!this.isSlider) {
      return headers.filter((h: ColumnHeader) => !['slides'].includes(h.value));
    } else {
      return headers.filter(
        (h: ColumnHeader) =>
          !['title', 'textColor', 'backgroundColor', 'backgroundImage', 'primaryClickAction'].includes(h.value),
      );
    }
  }
}
