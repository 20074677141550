import ApiService from '@/api/http/ApiService';
import { UiComponent } from '@/interfaces/models/UiComponent';
import { UiComponentType } from '@/enums/UiComponentType';
import { Page } from '@/interfaces/api/Page';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import axios, { AxiosPromise } from 'axios';

export default class UiComponentApiService extends ApiService<UiComponent> {
  constructor() {
    super('ui', 'v1');
  }

  public getUiComponents(
    type: UiComponentType,
    page: Page,
    filter: Filter,
  ): AxiosPromise<UiComponent[] | Pagination<UiComponent>> {
    if (page) {
      return axios.get(`${this.getBaseUrl()}/${type}s/`, {
        params: { page, limit: 10, customerGroup: filter.customerGroup || false },
      });
    }

    return axios.get(`${this.getBaseUrl()}/${type}s`);
  }

  public showUiComponent(data: { type: UiComponentType; id: string }): AxiosPromise<UiComponent> {
    return axios.get(`${this.getBaseUrl()}/${data.type}s/${data.id}`);
  }

  public storeUiComponent(param: { type: UiComponentType; data: Partial<UiComponent> }): AxiosPromise<UiComponent> {
    return axios.post(`${this.getBaseUrl()}/${param.type}s`, param.data);
  }

  public deleteUiComponent(data: { type: UiComponentType; id: string }): AxiosPromise<void> {
    return axios.delete(`${this.getBaseUrl()}/${data.type}s/${data.id}`);
  }

  public updateUiComponent(param: {
    type: UiComponentType;
    data: Partial<UiComponent>;
    id: string;
  }): AxiosPromise<UiComponent> {
    return axios.patch(`${this.getBaseUrl()}/${param.type}s/${param.id}`, param.data);
  }
}
