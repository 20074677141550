
import { Component } from 'vue-property-decorator';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { Article } from '@/interfaces/models/Article';
import { ExternalOrderProvider } from '@/enums/ExternalOrderProvider';

@Component({
  components: { VFormBuilder },
})
export default class WoltArticleOptionsForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get items() {
    return [
      {
        name: 'price',
        type: InputType.Price,
        label: 'article.form.price',
      },
      {
        name: 'visible',
        type: InputType.Checkbox,
        default: true,
        label: 'article.form.visible',
      },
      {
        name: 'enabled',
        type: InputType.Checkbox,
        default: true,
        label: 'article.form.enabledInFoodcard',
      },
      {
        name: 'provider',
        type: InputType.Hidden,
        default: ExternalOrderProvider.WOLT,
      },
    ];
  }

  get internalInitialValues() {
    const woltProvider = (this.initialValues as Article | undefined)?.externalOrderProviderSettings?.find(
      (settings) => settings.provider === ExternalOrderProvider.WOLT,
    );
    if (woltProvider) {
      return woltProvider;
    }
    return null;
  }
}
