
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PolygonMap } from '@/interfaces/models/PolygonMap';

@Component
export default class VPolygonMap extends Vue {
  @Prop({ type: Object, required: true }) public mapData!: PolygonMap;

  private map: any = null;
  private platform: any = null;

  public async mounted() {
    try {
      await this.loadHereScripts();
      this.loadMap();
    } catch (err) {}
  }

  private getCenterPos(prop: 'lat' | 'lng', points: number[]) {
    let pointsFiltered = points.filter((e, i, arr) => (prop !== 'lng' ? i % 2 === 0 : i % 2 !== 0));
    let min = Math.min(...pointsFiltered);
    let max = Math.max(...pointsFiltered);

    if (prop === 'lng' && max - min > 180) {
      pointsFiltered = pointsFiltered.map((v) => (v < max - 180 ? v + 360 : v));
      min = Math.min(...pointsFiltered);
      max = Math.max(...pointsFiltered);
    }

    let result = (min + max) / 2;

    if (prop === 'lng' && result > 180) {
      result -= 360;
    }

    return result;
  }

  private loadMap() {
    this.platform = new (window as any).H.service.Platform({
      apikey: process.env.VUE_APP_HERE_API_KEY,
    });

    const defaultLayers = this.platform?.createDefaultLayers();

    this.map = new (window as any).H.Map(this.$refs.mapContainer as HTMLElement, defaultLayers.vector.normal.map, {
      zoom: 12,
      center: this.mapData.coordinates[0]
        ? {
            lat: this.getCenterPos('lat', this.mapData.coordinates[0]),
            lng: this.getCenterPos('lng', this.mapData.coordinates[0]),
          }
        : null,
      pixelRatio: window.devicePixelRatio || 1,
    });

    window.addEventListener('resize', () => this.map.getViewPort().resize());

    const behavior = new (window as any).H.mapevents.Behavior(new (window as any).H.mapevents.MapEvents(this.map));
    const ui = (window as any).H.ui.UI.createDefault(this.map, defaultLayers);

    if (this.mapData.coordinates?.length) {
      const polygons = this.mapData.coordinates.map((c, index) => this.addPolygonToMap(c, index));
      this.map.addObjects(polygons);

      setTimeout(() => {
        this.map.getViewPort().resize();
      }, 5000);
    }
  }

  private addPolygonToMap(line: number[], index: number) {
    const lineString = new (window as any).H.geo.LineString.fromLatLngArray(line);

    const polygon = new (window as any).H.map.Polygon(lineString, {
      style: {
        fillColor: `rgba(${this.mapData.polygonsColorsRGB?.[index] || '138, 44, 140'}, 0.5)`,
        strokeColor: `rgba(${this.mapData.polygonsColorsRGB?.[index] || '60, 8, 140'}, 1)`,
        lineWidth: 8,
      },
    });

    return polygon;
  }

  private async loadHereScripts(): Promise<void> {
    const scripts = [
      'https://js.api.here.com/v3/3.1/mapsjs-core.js',
      'https://js.api.here.com/v3/3.1/mapsjs-service.js',
      'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
      'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
      'https://js.api.here.com/v3/3.1/mapsjs-clustering.js',
      'https://js.api.here.com/v3/3.1/mapsjs-data.js',
    ];

    for (const src of scripts) {
      await new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.head.appendChild(script);
      });
    }

    const css = document.createElement('link');
    css.rel = 'stylesheet';
    css.href = 'https://js.api.here.com/v3/3.1/mapsjs-ui.css';
    document.head.appendChild(css);
  }
}
