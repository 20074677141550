import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
export default class MFAApiService extends ApiService<any> {
  constructor() {
    super('auth/mfa');
  }

  public prepareMFA(): AxiosPromise<string> {
    return axios.post(`${this.getBaseUrl()}/setup`);
  }

  public enableMFA(payload: { code: string }): AxiosPromise<{ backupCodes: string[] }> {
    return axios.post(`${this.getBaseUrl()}/enable`, payload);
  }

  public disableMFA(payload: { code: string }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/disable`, payload);
  }

  public listBackupCodes(payload: { code: string }): AxiosPromise<{ backupCodes: string[] }> {
    return axios.post(`${this.getBaseUrl()}/backup-codes/view`, payload);
  }

  public regenerateBackupCodes(payload: { code: string }): AxiosPromise<{ backupCodes: string[] }> {
    return axios.patch(`${this.getBaseUrl()}/backup-codes/regenerate`, payload);
  }
}
