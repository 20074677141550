
import { Component, Emit, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import { Venue } from '@/interfaces/models/Venue';
import StackedForm from '@/mixins/StackedForm';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import Countries from '@/mixins/Countries';
import { HereApiService, Address, Location } from '@/api/http/HereApiService';
import { Company } from '@/interfaces/models/Company';
import { PaymentMethod } from '@/enums/PaymentMethod';

interface AddressForm {
  street: string;
  number: string;
  postalCode: string;
  city: string;
  longitude: number;
  latitude: number;
  location?: { coordinates: number[]; type: string };
}

@Component({
  components: { VFormBuilder },
})
export default class GeneralForm extends mixins(StackedForm, Countries) {
  @Prop({ type: Array, default: () => [] }) public venues!: Venue[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getData: () => any };
    deliveryAddress: InstanceType<typeof VFormBuilder> & { form: any };
    invoiceAddress: InstanceType<typeof VFormBuilder> & { form: any };
  };

  public useManualGpsInput: boolean = false;
  public useManualGpsInvoiceInput: boolean = false;

  get items() {
    return [
      {
        name: 'name',
        type: InputType.Text,
        label: 'company.form.name',
        clearable: true,
        rules: 'required',
      },
      {
        name: 'companyCode',
        type: InputType.Text,
        label: 'company.form.companyCode',
        clearable: true,
        rules: `required|uniq_company_code:${(this.initialValues as Company)?._id}`,
      },
      { name: 'phoneNumber', type: InputType.Text, label: 'company.form.phoneNumber', rules: 'required' },
      {
        name: 'subsidy',
        type: InputType.Decimal,
        label: 'company.form.subsidy',
        rules: 'min_value:0',
        default: 0,
      },
      {
        name: 'subsidyOrderMinimum',
        type: InputType.Decimal,
        label: 'company.form.subsidyOrderMinimum',
        default: 0,
        rules: 'min_value:0',
        visible: this.visibleCallback,
      },
      {
        name: 'storeDiscount',
        type: InputType.Decimal,
        label: 'company.form.storeDiscount',
        default: 0,
        rules: 'max_value:1|min_value:0',
      },
      {
        name: 'paymentMethod',
        type: InputType.Select,
        label: 'company.form.paymentMethod',
        rules: 'required',
        default: PaymentMethod.BANK_TRANSFER,
        items: this.paymentMethods,
        disabled: true,
      },
      {
        name: 'iban',
        type: InputType.Text,
        label: 'company.form.iban',
        rules: 'required|iban',
        visible: this.visibleIbanCallback,
      },
      {
        name: 'venue',
        type: InputType.Autocomplete,
        label: 'company.form.venue',
        items: this.venues,
        itemValue: '_id',
        itemText: 'name',
        clearable: true,
        rules: 'required',
      },
      {
        name: 'isPaidByCompany',
        type: InputType.Checkbox,
        label: 'company.form.isPaidByCompany',
      },
    ];
  }

  getAddressItems(typeAddress: string = 'deliveryAddress') {
    return [
      {
        name: 'country',
        type: InputType.Select,
        label: 'venue.form.country',
        rules: 'required',
        items: this.countries,
      },
      {
        name: 'street',
        type: InputType.Text,
        label: 'company.form.street',
        rules: 'required',
        change: typeAddress === 'deliveryAddress' ? this.changedAddress : null,
      },
      {
        name: 'number',
        type: InputType.Text,
        label: 'company.form.number',
        rules: 'required',
        change: typeAddress === 'deliveryAddress' ? this.changedAddress : null,
      },
      {
        name: 'city',
        type: InputType.Text,
        label: 'company.form.city',
        rules: 'required',
        change: typeAddress === 'deliveryAddress' ? this.changedAddress : null,
      },
      {
        name: 'postalCode',
        type: InputType.Text,
        label: 'company.form.postalCode',
        rules: 'required',
        change: typeAddress === 'deliveryAddress' ? this.changedAddress : null,
      },
      {
        name: 'latitude',
        type: InputType.Text,
        label: 'company.form.latitude',
        disabled: !this.useManualGpsInput,
        visible: typeAddress === 'deliveryAddress',
      },
      {
        name: 'longitude',
        type: InputType.Text,
        label: 'company.form.longitude',
        disabled: !this.useManualGpsInput,
        visible: typeAddress === 'deliveryAddress',
      },
    ];
  }

  get paymentMethods() {
    return [
      { value: PaymentMethod.SEPA, text: this.$t('paymentTypes.sepa') },
      { value: PaymentMethod.BANK_TRANSFER, text: this.$t('paymentTypes.bankTransfer') },
    ];
  }

  public visibleCallback(form: { subsidy: string; isPaidByCompany: boolean }) {
    return +form.subsidy > 0 || form.isPaidByCompany;
  }

  public visibleIbanCallback(form: { paymentMethod: PaymentMethod }) {
    return form.paymentMethod === PaymentMethod.SEPA;
  }

  getAddressValues(typeAddress: string): Partial<Company> {
    const initialValues: Partial<Company> = { ...this.initialValues };
    const address: any = initialValues[typeAddress as 'deliveryAddress' | 'invoiceAddress'];

    if (!address?.location?.coordinates || address.location.coordinates.length <= 1) {
      return initialValues;
    }

    address.longitude = address.location.coordinates[0];
    address.latitude = address.location.coordinates[1];

    return initialValues;
  }

  public getAddressData(typeAddress: string) {
    const addressData: any = { ...this.$refs[typeAddress as 'form' | 'deliveryAddress' | 'invoiceAddress'].form };

    if (!addressData.location) {
      addressData.location = { coordinates: [0, 0], type: 'Point' };
    }

    addressData.location.coordinates[0] = parseFloat(addressData.longitude) || 0.0;
    addressData.location.coordinates[1] = parseFloat(addressData.latitude) || 0.0;

    delete addressData.longitude;
    delete addressData.latitude;

    return addressData;
  }

  public async changedAddress(_: any, form: AddressForm) {
    await this.handleAddressChange(form, 'deliveryAddress');
  }

  public async changedInvoiceAddress(_: any, form: AddressForm) {
    await this.handleAddressChange(form, 'invoiceAddress');
  }

  private async handleAddressChange(form: AddressForm, refKey: 'deliveryAddress' | 'invoiceAddress') {
    if (form.street && form.number && form.postalCode && form.city) {
      this.$startLoading('venue.location');

      try {
        const location: Location | null = await HereApiService.getGeoCoordinates(form as unknown as Address);

        if (location) {
          if (!form.location) {
            form.location = { coordinates: [0, 0], type: 'Point' };
          }
          form.location.type = 'Point';
          form.location.coordinates[0] = location.lng;
          form.location.coordinates[1] = location.lat;

          this.$refs[refKey].setData({ ...form, latitude: location.lat, longitude: location.lng });
        } else {
          this.notifyError('notification.gpsError');
        }
      } catch (error) {
        this.notifyError('notification.gpsError');
      } finally {
        this.$stopLoading('venue.location');
      }
    }
  }

  public onUseDeliveryForInvoice(value: boolean) {
    if (value) {
      this.$refs.invoiceAddress.setData({ ...this.$refs.deliveryAddress.form });
    }
  }

  @Emit('notifyError')
  public notifyError(msg: string): string {
    return msg;
  }

  public getData() {
    const data = { ...this.$refs.form.form };

    if (data.paymentMethod !== PaymentMethod.SEPA) {
      delete data.iban;
    }

    if (!data.subsidy) {
      data.subsidy = 0;
    }

    return {
      ...data,
      deliveryAddress: { ...this.getAddressData('deliveryAddress') },
      invoiceAddress: { ...this.getAddressData('invoiceAddress') },
    };
  }
}
