
import { Component } from 'vue-property-decorator';
import MetricPage from '@/components/analytics/MetricPage.vue';
import { namespace } from 'vuex-class';
import VWrapper from '@/components/shared/VWrapper.vue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import AnalyticsLoader from '@/components/analytics/AnalyticsLoader.vue';
import { Venue } from '@/interfaces/models/Venue';
import ExportWidget from '@/components/shared/ExportWidget.vue';
import OrderServiceApi from '@/api/http/OrderServiceApi';
import { OrderFlavor } from '@/enums/OrderFlovor';
import Notification from '@/mixins/Notification';
import PaymentApiService from '@/api/http/PaymentApiService';
import ExportService from '@/api/http/ExportService';
import { CustomerGroup } from '@/enums/CustomerGroup';
import { mixins } from 'vue-class-component';
import { Download } from '@/interfaces/models/Download';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { Permission } from '@/enums/Permission';
import { AxiosResponse } from 'axios';
import { ExportFields } from '@/enums/ExportFields';
import { ExportType } from '@/enums/ExportType';
import moment from 'moment';
import { User } from '@/interfaces/models/User';
import { toUrl } from '@/util/helper';

const app = namespace('app');
const auth = namespace('auth');
const label = namespace('venueLabel');
const download = namespace('download');

interface ExportFormData {
  venue?: string | string[];
  flavor: OrderFlavor;
  from: string;
  to: string;
  lang?: string;
  labels?: string[];
  withoutTags?: boolean;
  expanded?: boolean;
  format?: string;
  venues?: string | string[];
  customerGroups?: string[];
}

@Component({
  computed: {
    ExportType() {
      return ExportType;
    },
    ExportFields() {
      return ExportFields;
    },
  },
  components: { ExportWidget, AnalyticsLoader, VRangePicker, MetricPage, VWrapper },
})
export default class Billing extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];
  @auth.State('user') public authUser!: User;
  @label.State('items') public labels!: VenueLabel[];
  @label.Action('fetch') public getLabels!: any;
  @download.Action('addDownload') public addDownload!: (d: Download) => void;

  get defaultCustomerGroup() {
    return this.authUser.customerGroup ? [this.authUser.customerGroup] : null;
  }

  public async mounted() {
    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getLabels();
    }
  }

  public exportOrderCsv(data: ExportFormData) {
    this.$startLoading('orderCsv');
    const api: OrderServiceApi = new OrderServiceApi();
    api
      .orderCsv({ ...data, lang: this.$i18n.locale })
      .then(() => this.notifySuccess('notification.successfulDownload'))
      .finally(() => {
        this.$stopLoading('orderCsv');
      });
  }

  public exportOrderPaymentMethodCsv(data: ExportFormData) {
    this.$startLoading('orderPaymentMethodCsv');
    const api: OrderServiceApi = new OrderServiceApi();
    let format: string = ExportType.CSV;
    if (data && data.format && data.format === ExportType.XLSX) {
      format = ExportType.XLSX;
    }
    api
      .orderPaymentMethodCsv({ ...data, format, lang: this.$i18n.locale })
      .then(() => this.notifySuccess('notification.successfulDownload'))
      .finally(() => {
        this.$stopLoading('orderPaymentMethodCsv');
      });
  }

  public exportSalesCsv(data: ExportFormData) {
    const api: OrderServiceApi = new OrderServiceApi();
    const payload = { ...data };
    if (payload.to && payload.to.split(' ').length === 1) {
      payload.to = `${payload.to} 23:59`;
    }
    payload.from = moment(data.from).format('YYYY-MM-DD HH:mm');

    if (payload && payload.format && payload.format === ExportType.PDF) {
      api.salesPdf(payload).then((res: AxiosResponse<{ file: string }>) => {
        this.addDownload({ file: res.data.file, timeout: 60, handler: 'sales', inProgress: false, done: false });
        this.notifyInfo('notification.downloadInProgress');
      });
    } else {
      this.$startLoading('salesCsv');
      api
        .salesCsv({ ...payload, lang: this.$i18n.locale })
        .then(() => this.notifySuccess('notification.successfulDownload'))
        .finally(() => {
          this.$stopLoading('salesCsv');
        });
    }
  }

  public getCustomerGroup(data: ExportFormData): CustomerGroup {
    if (data.venue) {
      const venue: Venue = this.venues.find((v: Venue) => v._id === data.venue)!;
      if (venue) {
        return venue.customerGroup;
      }
    }

    return CustomerGroup.Default;
  }

  public exportBillingSummary(data: ExportFormData) {
    const api: PaymentApiService = new PaymentApiService();
    if (
      this.getCustomerGroup(data) === CustomerGroup.Subway ||
      this.getCustomerGroup(data) === CustomerGroup.SubwayFr ||
      this.getCustomerGroup(data) === CustomerGroup.SubwayLu
    ) {
      api
        .billingSummaryPdfSubway({ ...data, locale: this.$i18n.locale, v2: true })
        .then((res: AxiosResponse<{ file: string }>) => {
          this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
          this.notifyInfo('notification.downloadInProgress');
        });
    } else {
      api
        .billingSummaryPdf({ ...data, locale: this.$i18n.locale, v2: true })
        .then((res: AxiosResponse<{ file: string }>) => {
          this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
          this.notifyInfo('notification.downloadInProgress');
        });
    }
  }

  public legacyExportBillingSummary(data: ExportFormData) {
    const api: PaymentApiService = new PaymentApiService();
    if (
      this.getCustomerGroup(data) === CustomerGroup.Subway ||
      this.getCustomerGroup(data) === CustomerGroup.SubwayFr ||
      this.getCustomerGroup(data) === CustomerGroup.SubwayLu
    ) {
      api
        .billingSummaryPdfSubway({ ...data, locale: this.$i18n.locale })
        .then((res: AxiosResponse<{ file: string }>) => {
          this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
          this.notifyInfo('notification.downloadInProgress');
        });
    } else {
      api.billingSummaryPdf(data).then((res: AxiosResponse<{ file: string }>) => {
        this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
        this.notifyInfo('notification.downloadInProgress');
      });
    }
  }

  public legacyExportBillingPlainSummary(data: ExportFormData) {
    const api: PaymentApiService = new PaymentApiService();
    api.billingSummaryPlainPdf(data).then((res: AxiosResponse<{ file: string }>) => {
      this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
      this.notifyInfo('notification.downloadInProgress');
    });
  }

  public exportBillingPlainSummary(data: ExportFormData) {
    const api: PaymentApiService = new PaymentApiService();
    api
      .billingSummaryPlainPdf({ ...data, locale: this.$i18n.locale, v2: true })
      .then((res: AxiosResponse<{ file: string }>) => {
        this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
        this.notifyInfo('notification.downloadInProgress');
      });
  }

  public exportBilling(data: ExportFormData) {
    const api: PaymentApiService = new PaymentApiService();
    if (
      this.getCustomerGroup(data) === CustomerGroup.Subway ||
      this.getCustomerGroup(data) === CustomerGroup.SubwayFr ||
      this.getCustomerGroup(data) === CustomerGroup.SubwayLu
    ) {
      api
        .billingPdfSubway({ ...data, locale: this.$i18n.locale, v2: true })
        .then((res: AxiosResponse<{ file: string }>) => {
          this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
          this.notifyInfo('notification.downloadInProgress');
        });
      return;
    }
    if (data.format === ExportType.CSV) {
      api.billingCsv({ ...data, locale: this.$i18n.locale, v2: true }).then((res: AxiosResponse<string>) => {
        toUrl(
          new Blob([res.data], { type: 'text/csv;charset=utf-8;' }),
          `billing-${moment().format('DD-MM-YYYY')}.csv`,
        );
        this.notifyInfo('notification.downloadInProgress');
      });
      return;
    }
    api.billingPdf({ ...data, locale: this.$i18n.locale, v2: true }).then((res: AxiosResponse<{ file: string }>) => {
      this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
      this.notifyInfo('notification.downloadInProgress');
    });
  }

  public legacyExportBilling(data: ExportFormData) {
    const api: PaymentApiService = new PaymentApiService();
    if (
      this.getCustomerGroup(data) === CustomerGroup.Subway ||
      this.getCustomerGroup(data) === CustomerGroup.SubwayFr ||
      this.getCustomerGroup(data) === CustomerGroup.SubwayLu
    ) {
      api.billingPdfSubway({ ...data, locale: this.$i18n.locale }).then((res: AxiosResponse<{ file: string }>) => {
        this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
        this.notifyInfo('notification.downloadInProgress');
      });
    } else {
      api.billingPdf(data).then((res: AxiosResponse<{ file: string }>) => {
        this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
        this.notifyInfo('notification.downloadInProgress');
      });
    }
  }

  public exportSalesGroup(data: ExportFormData) {
    this.$startLoading('exportSalesGroup');
    const api: OrderServiceApi = new OrderServiceApi();

    let format: string = ExportType.CSV;
    if (data && data.format && data.format === ExportType.XLSX) {
      format = ExportType.XLSX;
    }

    data.venues = data?.venue ? data.venue : [];
    delete data.venue;
    api
      .salesGroup({ ...data })
      .then(() => this.notifySuccess('notification.successfulDownload'))
      .finally(() => {
        this.$stopLoading('exportSalesGroup');
      });
  }

  public exportDatev(data: Partial<ExportFormData>) {
    const api: ExportService = new ExportService();
    this.$startLoading('datev');
    data.venues = data?.venue ? data.venue : [];
    delete data.venue;

    api
      .datevCsv(data as { from: string; to: string; customerGroups: CustomerGroup[]; venues: string[] })
      .then((res: AxiosResponse<string>) => {
        toUrl(new Blob([res.data], { type: 'text/csv;charset=utf-8;' }), `datev-${moment().format('DD-MM-YYYY')}.csv`);
        this.notifyInfo('notification.downloadInProgress');
      })
      .finally(() => {
        this.$stopLoading('datev');
      });
  }

  public exportArticlesOverview(data: ExportFormData) {
    const api: PaymentApiService = new PaymentApiService();
    this.$startLoading('exportArticlesOverview');

    api
      .articlesOverview({ ...data, locale: this.$i18n.locale, v2: true })
      .then((res: AxiosResponse<{ file: string }>) => {
        this.addDownload({ file: res.data.file, timeout: 60, handler: 'payment', inProgress: false, done: false });
        this.notifyInfo('notification.downloadInProgress');
      });
  }

  public handleErrorValidation(data: string[]) {
    this.notifyError('billing.form.requiredField');
  }
}
