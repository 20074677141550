import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';

interface MFAState {
  action: any;
  mfaModalVisible: boolean;
}

const state: MFAState = {
  action: null,
  mfaModalVisible: false,
};

const actions: ActionTree<MFAState, RootState> = {
  openMFAModal({ commit }, mfaAction: any) {
    commit('openMFAModal', mfaAction);
  },
  closeMFAModal({ commit }) {
    commit('closeMFAModal');
  },
};

const mutations: MutationTree<MFAState> = {
  openMFAModal(state: MFAState, mfaAction: any) {
    state.action = mfaAction;
    state.mfaModalVisible = true;
  },
  closeMFAModal(state: MFAState) {
    state.action = null;
    state.mfaModalVisible = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
