
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import { getCookieData, setCookieData } from '@/util/cookieManager';

@Component
export default class VColumnSelect extends Vue {
  @Prop({ type: Array, required: true }) public source!: ColumnHeader[];
  @Prop({ type: String, required: false }) public tableKey!: string;
  public headers: ColumnHeader[] = [];
  public selectedHeaders: string[] = [];

  public saveSettings(type: string, header: ColumnHeader[]): void {
    setCookieData<ColumnHeader[]>(type, header, 1);
  }

  public loadSettings(type: string): ColumnHeader[] | null {
    return getCookieData<ColumnHeader[]>(type);
  }

  public resetSelectedHeaders() {
    const filteredHeaders = this.source.filter((item) =>
      this.$vuetify.breakpoint.smAndDown ? item.defaultOnMobile : item.default,
    );

    this.setHeaders(filteredHeaders);
  }

  private setHeaders(headers: ColumnHeader[]): ColumnHeader[] {
    this.headers = headers;
    this.selectedHeaders = headers.map((item) => item.value);
    return this.headers;
  }

  @Emit('change')
  public mounted(): ColumnHeader[] {
    const headers = this.tableKey ? this.loadSettings(this.tableKey) : null;
    if (headers) {
      return this.setHeaders(headers);
    }

    const filteredHeaders = this.source.filter((item) =>
      this.$vuetify.breakpoint.smAndDown ? item.defaultOnMobile : item.default,
    );

    return this.setHeaders(filteredHeaders);
  }

  @Watch('selectedHeaders')
  @Emit('change')
  public onHeaderSelectionChange() {
    this.headers = this.source.filter((item: ColumnHeader) => this.selectedHeaders.indexOf(item.value) > -1);

    this.saveSettings(this.tableKey, this.headers);
    return this.headers;
  }
}
