
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import ConstrainTable from '../../components/constrain/ConstrainTable.vue';
import Filter from '@/interfaces/api/Filter';

const venue = namespace('venue');
const constrain = namespace('constrain');

@Component({
  components: { ConstrainTable, VEmptyState, VWrapper },
})
export default class Constrain extends Vue {
  @venue.State('active') public venue!: Venue;

  @constrain.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @constrain.Action('fetch') public getConstrains!: any;
  @constrain.Action('destroy') public destroy!: any;
  @constrain.State((state) => state.pagination.total) public total!: number;

  @constrain.State('items') public items!: Constrain[];

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
    if (this.venue) {
      this.$startLoading('table');
      this.setFilter({ venue: this.venue._id });
      this.getConstrains({ page: 1 });
      this.$stopLoading('table');
    }
  }

  public deleteConstrain(item: any) {
    this.destroy({ constrains: [item._id] });
  }

  @Watch('venue')
  public onVenueChange() {
    if (this.venue && this.venue._id) {
      if (this.venue) {
        this.$startLoading('constrain');
        this.setFilter({ venue: this.venue._id });
        this.getConstrains({ page: 1 });
        this.$stopLoading('constrain');
      }
    }
  }
}
