
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '../shared/table/VTable.vue';
import { SalesOverview } from '@/interfaces/models/SalesOverview';
import { VCardText, VSimpleTable } from 'vuetify/lib';
import headers from './headers';
import Sale from '@/interfaces/models/Sale';

@Component({
  components: { VTable, VSimpleTable },
})
export default class SalesOverviewTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: SalesOverview[];
  @Prop({ type: Number, required: true }) public total!: number;

  public headers: { text: string; value: string }[] = headers;

  getTotalOrders(sales: Sale[], provider: string) {
    const sale = sales.find((s: Sale) => s.provider === provider);
    return sale ? sale.totalOrders : '-';
  }

  getTotalAmount(sales: Sale[], provider: string) {
    const sale = sales.find((s: Sale) => s.provider === provider);
    return sale ? `€${parseFloat(sale.totalAmount.$numberDecimal).toFixed(2)}` : '-';
  }

  getImageDimensions(value: 'wolt' | 'jet' | 'uber_eats' | 'terminal' | 'web_app') {
    const dimensions = {
      wolt: { width: 30, height: 20 },
      jet: { width: 60, height: 20 },
      uber_eats: { width: 50, height: 20 },
      terminal: { width: 20, height: 20 },
      web_app: { width: 20, height: 20 },
    };
    return dimensions[value] || { width: 40, height: 20 };
  }

  getTotalOrdersByProvider(provider: string) {
    let totalOrders = 0;

    this.items.forEach((venue) => {
      venue.sales.forEach((sale) => {
        if (sale.provider === provider) {
          totalOrders += sale.totalOrders;
        }
      });
    });

    return totalOrders;
  }

  getTotalAmountByProvider(provider: string) {
    let totalAmount = 0;

    this.items.forEach((venue) => {
      venue.sales.forEach((sale) => {
        if (sale.provider === provider) {
          totalAmount += parseFloat(sale.totalAmount.$numberDecimal);
        }
      });
    });

    return `€${totalAmount.toFixed(2)}`;
  }
}
