
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import items from './items';
import { Permission } from '@/enums/Permission';

interface NavbarItem {
  key: string;
  group: string;
  title?: string;
  icon: string;
  to?: string;
  can: Permission | Permission[];
  items?: NavbarItem[];
}

@Component
export default class Navbar extends Vue {
  @Prop({ type: Boolean, required: true }) public drawer!: boolean;

  public navbarItems: any = items;

  public drawerVisible: boolean = true;

  public canAccessGroupItemMenu(items: any) {
    return this.$canAny(items.map((item: any) => item.can));
  }

  @Watch('drawer')
  public onDrawerChange() {
    this.drawerVisible = this.drawer;
  }

  @Watch('drawerVisible')
  public onDrawerVisibleChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.$emit('drawer', val);
    }
  }
}
