
import { Component } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import Filter from '@/interfaces/api/Filter';
import { PaymentMethod } from '@/enums/PaymentMethod';
import uuid from 'uuid/v4';
import LightspeedApiService from '@/api/http/LightspeedApiService';
import { Venue } from '@/interfaces/models/Venue';
import { CheckoutIntegration, HellotessTender } from '../../../interfaces/models/CheckoutIntegration';
import { SimphonyIntegration } from '@/interfaces/models/SimphonyIntegration';
import { VCard, VCardActions, VCardText, VDialog } from 'vuetify/lib';
import { venueFilter } from '@/util/helper';
import SimphonyApiService from '@/api/http/SimphonyApiService';
import SyncPricesApiService from '@/api/http/SyncPricesApiService';
import { ValidationObserver } from 'vee-validate';
import { Permission } from '@/enums/Permission';
import { SimphonySyncMode } from '@/enums/SimphonySyncMode';
import { TranslateResult } from 'vue-i18n';

const app = namespace('app');
const venue = namespace('venue');
const table = namespace('table');

const ANTARA = 'antara';
const DELIVERECT = 'deliverect';
const HELLOTESS = 'hellotess';
const GASTROFIX = 'gastrofix';
const SIMPHONY = 'simphony';
const LIGHTSPEED_K_SERIES = 'lightspeed_k_series';
const VECTRON = 'vectron';
const LINA_LOCAL = 'lina_local';
const LINA_WEB = 'lina_web';

interface SimphonyTender {
  tenderId: number;
  paymentMethod: string;
  name: string;
  uuid?: string;
}

interface Tender {
  paymentMethod: string;
  channelInside: string;
  channelTakeaway: string;
  channelDelivery: string;
  uuid?: string;
}

interface Table {
  table: string;
  name: string;
  channelDelivery: string;
  channelInside: string;
  channelTakeaway: string;
  uuid?: string;
  tenders: Tender[];
}

interface Deliverect {
  locationId: string;
  posLocationId: string;
  defaultChannel: string;
  useVirtualOrders: boolean;
  useSecondaryCodeAsCheckId: boolean;
}

interface PriceType {
  orderProfile: string;
  value: string;
}

@Component({
  components: { VFormBuilder, VDialog, VCard, VCardText, VCardActions, ValidationObserver },
})
export default class CheckoutIntegrationForm extends mixins(StackedForm, Notification) {
  @app.State('venues') public venues!: Venue[];

  @venue.State('active') public activeVenue!: Venue;
  @venue.State('checkoutIntegration') public checkoutIntegration!: CheckoutIntegration;
  @venue.Action('getCheckoutIntegration') public getCheckoutIntegration!: any;
  @venue.Action('copyFromToVenue') public copyFromToVenue!: (fromTo: {
    from: string;
    to: string[];
  }) => Promise<Boolean>;

  @table.State('items') public tablesItem!: Table[];
  @table.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @table.Action('fetch') public getTables!: any;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
    integrationProps: InstanceType<typeof VFormBuilder> & { form: any };
    deliverectProps: InstanceType<typeof VFormBuilder> & { form: any };
    simphonyProps: InstanceType<typeof VFormBuilder> & { form: any };
    tableProps: InstanceType<typeof VFormBuilder> & any;
    tenderProps: InstanceType<typeof VFormBuilder> & any;
    simphonyTenderProps: InstanceType<typeof VFormBuilder> & any;
    hellotessTenderProps: InstanceType<typeof VFormBuilder> & any;
    lightspeedProps: InstanceType<typeof VFormBuilder> & any;
    vectronProps: InstanceType<typeof VFormBuilder> & any;
    observer: InstanceType<typeof ValidationObserver>;
  };
  public antara: string = ANTARA;
  public deliverect: string = DELIVERECT;
  public hellotess: string = HELLOTESS;
  public gastrofix: string = GASTROFIX;
  public simphony: string = SIMPHONY;
  public lightspeedKSeries: string = LIGHTSPEED_K_SERIES;
  public linaLocal: string = LINA_LOCAL;
  public linaWeb: string = LINA_WEB;
  public vectron: string = VECTRON;
  public typeConfiguration: string = '';
  public initiatedStatus: boolean = false;
  public prices: PriceType[] = [];

  public copyVenueDialog: boolean = false;
  public copyVenueModel: {
    from: string;
    to: string[];
  } = {
    from: '',
    to: [],
  };
  public toVenueArray: Venue[] = [];

  public initialDeliverectVelues: Deliverect = {
    locationId: '',
    posLocationId: '',
    defaultChannel: '',
    useVirtualOrders: false,
    useSecondaryCodeAsCheckId: false,
  };

  public initialSimphonyVelues: SimphonyIntegration = {
    serviceUrl: '',
    orgname: '',
    orgShortName: '',
    locRef: '',
    rvcRef: '',
    username: '',
    password: '',
    clientId: '',
    authUrl: '',
    cancelTenderType: null,
    cashPaymentCancelTimeout: null,
    useExternalId: false,
    useSanifairAsTender: false,
    useTableAsEmployee: false,
    useCodeAsOrderCode: false,
    useSecondaryCodeAsOrdercode: false,
    defaultEmployee: null,
    defaultOrderType: null,
    orderTypeInside: null,
    orderTypeTakeAway: null,
    orderTypeDelivery: null,
    orderTypeFoodspot: null,
    serviceChargeTip: null,
    serviceChargeDeliveryFee: null,
    orderTypeInsideTerminal: null,
    orderTypeTakeAwayTerminal: null,
    firetimeEnabled: false,
    firetimeOffset: null,
    averageDeliveryTime: null,
    sendOrderNotes: false,
    hideTableName: false,
    healthcheckEnabled: false,
    multiConventional: false,
    useCancelTenderType: false,
    excludedTenders: null,
    multiConventionalRevenueCenters: null,
    sentBoardingPassEnabled: false,
    sentRbiLoyaltyEnabled: false,
    recalculatePayment: false,
    replaceCashOrdersByVirtualOrders: false,
    autoSyncEnabled: false,
    priceSequence: 1,
  };

  public tables: Table[] = [];
  public simphonyTenders: SimphonyTender[] = [];
  public hellotessTenders: HellotessTender[] = [];

  public simphonySyncButtons: Array<{
    label: TranslateResult;
    success: string;
    error: string;
    mode: SimphonySyncMode;
  }> = [
    {
      label: this.$t('simphony.syncFoodcard'),
      success: 'simphony.notification.foodcardSyncSuccess',
      error: 'simphony.notification.foodcardSyncError',
      mode: SimphonySyncMode.PRICE_AND_ACTIVE,
    },
    {
      label: this.$t('simphony.syncPrices'),
      success: 'simphony.notification.pricesSyncSuccess',
      error: 'simphony.notification.pricesSyncError',
      mode: SimphonySyncMode.PRICE_ONLY,
    },
    {
      label: this.$t('simphony.syncActive'),
      success: 'simphony.notification.activeSyncSuccess',
      error: 'simphony.notification.activeSyncError',
      mode: SimphonySyncMode.ACTIVE_ONLY,
    },
  ];

  async mounted() {
    this.copyVenueModel.from = this.activeVenue._id;
    if (this.initialValues && (this.initialValues as Venue).checkoutIntegration === this.lightspeedKSeries) {
      if (!this.$can(Permission.VENUE_CHECKOUTINTEGRATION_VIEW)) return;
      await this.getCheckoutIntegration({
        venue: (this.initialValues as Venue)._id,
        integrationType: (this.initialValues as Venue)?.checkoutIntegration,
      });
    }
  }

  public filter(item: Venue, queryText: string): boolean {
    return venueFilter(item, queryText.trim());
  }

  public onVenueCopyClose() {
    this.copyVenueDialog = false;
  }
  public async onVenueCopySave() {
    if (!this.copyVenueModel.to.length) {
      this.notifyError(this.$t('copy_venue.not_selected_to') as string);
    } else {
      try {
        await this.copyFromToVenue(this.copyVenueModel);
        this.notifySuccess(this.$t('copy_venue.success') as string);
      } catch (error) {
        console.log(error);
        this.notifyError(this.$t('copy_venue.error') as string);
      }

      this.copyVenueDialog = false;
    }
  }

  public async initData() {
    const active: Venue = this.initialValues as Venue;
    const venue: string = this.$route.params.id || '';
    this.initiatedStatus = true;

    if (venue) {
      await this.loadVenueTables(venue);
    }

    if (active?.checkoutIntegration) {
      await this.loadCheckoutIntegration(active);
    }

    const integration = this.getData();
    if (integration && integration.checkoutIntegration) {
      this.typeConfiguration = integration.checkoutIntegration;
      this.handleCheckoutIntegration();
    }
  }

  private async loadVenueTables(venue: string) {
    this.$startLoading('table');
    this.setFilter({ venue });
    await this.getTables();
    this.$stopLoading('table');
  }

  private async loadCheckoutIntegration(active: Venue) {
    if (!this.$can(Permission.VENUE_CHECKOUTINTEGRATION_VIEW)) return;
    await this.getCheckoutIntegration({
      venue: active._id,
      integrationType: active.checkoutIntegration,
    });
  }

  private handleCheckoutIntegration() {
    if (this.checkoutIntegration?.lightspeed) {
      this.setupLightspeedIntegration();
    }

    if (this.checkoutIntegration?.deliverect) {
      this.setupDeliverectIntegration();
    }

    if (this.checkoutIntegration?.simphony) {
      this.setupSimphonyIntegration();
    }

    if (this.checkoutIntegration?.hellotess) {
      this.hellotessTenders = (this.checkoutIntegration.hellotess.tenders || []).map((tender: HellotessTender) => ({
        ...tender,
        uuid: uuid(),
      }));
    }
  }

  private setupLightspeedIntegration() {
    this.prices = this.checkoutIntegration.lightspeed?.priceMappings?.prices ?? [];
  }

  private setupDeliverectIntegration() {
    this.initialDeliverectVelues = { ...this.checkoutIntegration.deliverect };
    this.tables = this.checkoutIntegration.deliverect.tables.map((table: Table) => ({
      ...table,
      uuid: uuid(),
      tenders: table.tenders.map((tender: Tender) => ({
        ...tender,
        uuid: uuid(),
      })),
    }));
  }

  private setupSimphonyIntegration() {
    this.initialSimphonyVelues = { ...this.checkoutIntegration.simphony };

    if (this.initialSimphonyVelues?.excludedTenders) {
      const excludedTenders = this.initialSimphonyVelues.excludedTenders as unknown as number[];
      this.initialSimphonyVelues.excludedTenders = excludedTenders.join(',');
    }

    if (this.initialSimphonyVelues?.multiConventionalRevenueCenters) {
      const multiConventionalRevenueCenters = this.initialSimphonyVelues
        .multiConventionalRevenueCenters as unknown as number[];
      this.initialSimphonyVelues.multiConventionalRevenueCenters = multiConventionalRevenueCenters.join(',');
    }

    this.simphonyTenders = this.checkoutIntegration.simphony.tenders.map((tender: SimphonyTender) => ({
      ...tender,
      uuid: uuid(),
    }));
  }

  public getInitiatedStatus() {
    return this.initiatedStatus;
  }

  get checkoutIntegrationItems() {
    return [
      {
        name: 'checkoutIntegration',
        type: InputType.Select,
        default: 'none',
        label: 'venue.form.checkoutIntegration',
        items: this.checkoutIntegrationTypes,
        change: this.onConfChange,
      },
      {
        name: 'checkoutIn',
        type: InputType.Select,
        default: 'none',
        label: 'venue.form.checkoutIn',
        items: this.orderStatusTypes,
        disabled: this.ciSettingsDisabledCallback,
      },
      { name: 'stock', type: InputType.Text, label: 'venue.form.stock' },
      { name: 'hasPrinter', type: InputType.Checkbox, default: false, label: 'venue.form.printerIntegration' },
      {
        name: 'printIn',
        type: InputType.Select,
        default: 'none',
        label: 'venue.form.printIn',
        items: this.orderStatusTypes,
        disabled: this.printerSettingsDisabledCallback,
      },
    ];
  }

  /*  Important - only for Antara!  */
  get checkoutIntegrationProps() {
    return [
      {
        name: 'ip',
        type: InputType.Text,
        label: 'Ip',
        default: null,
        items: this.checkoutIntegrationTypes,
        visible: this.typeConfiguration === ANTARA,
      },
      {
        name: 'port',
        type: InputType.Text,
        label: 'Port',
        default: null,
        visible: this.typeConfiguration === ANTARA,
      },
      {
        name: 'storeId',
        type: InputType.Text,
        label: 'Store ID',
        default: null,
        rules: 'required',
        visible: this.typeConfiguration === ANTARA || this.typeConfiguration === HELLOTESS,
      },
      {
        name: 'gastrofixBusinessUnit',
        type: InputType.Text,
        default: null,
        label: 'Gastrofix Business Unit',
        visible: this.typeConfiguration === GASTROFIX,
      },
      {
        name: 'gastrofixApiKey',
        type: InputType.Text,
        default: null,
        label: 'Gastrofix Api Key',
        visible: this.typeConfiguration === GASTROFIX,
      },
      {
        name: 'hellotessApiBaseUrl',
        type: InputType.Text,
        default: null,
        label: 'Hellotess Api Base Url',
        rules: 'required',
        visible: this.typeConfiguration === HELLOTESS,
      },
      {
        name: 'hellotessApiKey',
        type: InputType.Text,
        default: null,
        label: 'Hellotess Api Key',
        rules: 'required',
        visible: this.typeConfiguration === HELLOTESS,
      },
      {
        name: 'paymentMethod',
        type: InputType.Text,
        default: null,
        label: 'Payment Method',
        rules: 'required',
        visible: this.typeConfiguration === HELLOTESS,
      },
      {
        name: 'useV2',
        type: InputType.Checkbox,
        default: false,
        label: 'Use V2',
        visible: this.typeConfiguration === HELLOTESS,
      },
      {
        name: 'scheduleSync',
        type: InputType.Checkbox,
        default: false,
        label: 'Schedule',
        disabled: true,
        visible: this.typeConfiguration === ANTARA,
      },
      {
        name: 'lastResult',
        type: InputType.Text,
        label: 'Last result',
        default: 'none',
        disabled: true,
        visible: this.typeConfiguration === ANTARA,
      },
    ];
  }

  get deliverectItemProps() {
    return [
      {
        name: 'locationId',
        type: InputType.Text,
        label: 'Location Id',
        rules: 'required',
        change: (value: string) => (this.initialDeliverectVelues.locationId = value),
      },
      {
        name: 'posLocationId',
        type: InputType.Text,
        label: 'Pos Location Id',
        rules: 'required',
        change: (value: string) => (this.initialDeliverectVelues.posLocationId = value),
      },
      {
        name: 'defaultChannel',
        type: InputType.Text,
        label: 'Default Channel',
        rules: 'required',
        change: (value: string) => (this.initialDeliverectVelues.defaultChannel = value),
      },
      {
        name: 'useVirtualOrders',
        type: InputType.Checkbox,
        default: false,
        label: 'Use Virtual Orders',
        rules: 'required',
        change: (value: boolean) => (this.initialDeliverectVelues.useVirtualOrders = value),
      },
      {
        name: 'useSecondaryCodeAsCheckId',
        type: InputType.Checkbox,
        default: false,
        label: 'Use Secondary Code As CheckId',
        rules: 'required',
        change: (value: boolean) => (this.initialDeliverectVelues.useSecondaryCodeAsCheckId = value),
      },
    ];
  }

  get simphonyItemProps() {
    return [
      {
        name: 'serviceUrl',
        type: InputType.Text,
        label: 'venue.form.checkoutIntegrationProps.serviceUrl',
        rules: 'required',
        change: (value: string) => (this.initialSimphonyVelues.serviceUrl = value),
      },
      {
        name: 'orgname',
        type: InputType.Text,
        label: 'venue.form.checkoutIntegrationProps.orgname',
        rules: 'required',
        change: (value: string) => (this.initialSimphonyVelues.orgname = value),
      },
      {
        name: 'orgShortName',
        type: InputType.Text,
        label: 'venue.form.checkoutIntegrationProps.orgShortName',
        rules: 'required',
        change: (value: string) => (this.initialSimphonyVelues.orgShortName = value),
      },
      {
        name: 'locRef',
        type: InputType.Text,
        label: 'venue.form.checkoutIntegrationProps.locRef',
        rules: 'required',
        change: (value: string) => (this.initialSimphonyVelues.locRef = value),
      },
      {
        name: 'rvcRef',
        type: InputType.Text,
        label: 'venue.form.checkoutIntegrationProps.rvcRef',
        rules: 'required',
        change: (value: string) => (this.initialSimphonyVelues.rvcRef = value),
      },
      {
        name: 'username',
        type: InputType.Text,
        label: 'venue.form.checkoutIntegrationProps.username',
        rules: 'required',
        change: (value: string) => (this.initialSimphonyVelues.username = value),
      },
      {
        name: 'password',
        type: InputType.Password,
        label: 'venue.form.checkoutIntegrationProps.password',
        rules: 'required',
        change: (value: string) => (this.initialSimphonyVelues.password = value),
      },
      {
        name: 'clientId',
        type: InputType.Text,
        label: 'venue.form.checkoutIntegrationProps.clientId',
        rules: 'required',
        change: (value: string) => (this.initialSimphonyVelues.clientId = value),
      },
      {
        name: 'authUrl',
        type: InputType.Text,
        label: 'venue.form.checkoutIntegrationProps.authUrl',
        rules: 'required',
        change: (value: string) => (this.initialSimphonyVelues.authUrl = value),
      },
      {
        name: 'defaultEmployee',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.defaultEmployee',
        rules: 'integer',
        change: (value: string) => (this.initialSimphonyVelues.defaultEmployee = value && value !== '' ? value : null),
      },
      {
        name: 'defaultOrderType',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.defaultOrderType',
        rules: 'integer',
        change: (value: string) => (this.initialSimphonyVelues.defaultOrderType = value && value !== '' ? value : null),
      },
      {
        name: 'orderTypeInside',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.orderTypeInside',
        rules: 'integer',
        change: (value: string) => (this.initialSimphonyVelues.orderTypeInside = value && value !== '' ? value : null),
      },
      {
        name: 'orderTypeTakeAway',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.orderTypeTakeAway',
        rules: 'integer',
        change: (value: string) =>
          (this.initialSimphonyVelues.orderTypeTakeAway = value && value !== '' ? value : null),
      },
      {
        name: 'orderTypeDelivery',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.orderTypeDelivery',
        rules: 'integer',
        change: (value: string) =>
          (this.initialSimphonyVelues.orderTypeDelivery = value && value !== '' ? value : null),
      },
      {
        name: 'orderTypeFoodspot',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.orderTypeFoodspot',
        rules: 'integer',
        change: (value: string) =>
          (this.initialSimphonyVelues.orderTypeFoodspot = value && value !== '' ? value : null),
      },
      {
        name: 'serviceChargeTip',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.serviceChargeTip',
        rules: 'integer',
        change: (value: string) => (this.initialSimphonyVelues.serviceChargeTip = value && value !== '' ? value : null),
      },
      {
        name: 'serviceChargeDeliveryFee',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.serviceChargeDeliveryFee',
        rules: 'integer',
        change: (value: string) =>
          (this.initialSimphonyVelues.serviceChargeDeliveryFee = value && value !== '' ? value : null),
      },
      {
        name: 'orderTypeInsideTerminal',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.orderTypeInsideTerminal',
        rules: 'integer',
        change: (value: string) =>
          (this.initialSimphonyVelues.orderTypeInsideTerminal = value && value !== '' ? value : null),
      },
      {
        name: 'orderTypeTakeAwayTerminal',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.orderTypeTakeAwayTerminal',
        rules: 'integer',
        change: (value: string) =>
          (this.initialSimphonyVelues.orderTypeTakeAwayTerminal = value && value !== '' ? value : null),
      },
      {
        name: 'firetimeOffset',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.firetimeOffset',
        rules: 'integer',
        change: (value: string) => (this.initialSimphonyVelues.firetimeOffset = value && value !== '' ? value : null),
      },
      {
        name: 'averageDeliveryTime',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.averageDeliveryTime',
        rules: 'integer',
        change: (value: string) =>
          (this.initialSimphonyVelues.averageDeliveryTime = value && value !== '' ? value : null),
      },
      {
        name: 'cancelTenderType',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.cancelTenderType',
        rules: 'integer',
        change: (value: string) => (this.initialSimphonyVelues.cancelTenderType = value && value !== '' ? value : null),
      },
      {
        name: 'cashPaymentCancelTimeout',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.cashPaymentCancelTimeout',
        rules: 'integer',
        change: (value: string) =>
          (this.initialSimphonyVelues.cashPaymentCancelTimeout = value && value !== '' ? value : null),
      },
      {
        name: 'excludedTenders',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.excludedTenders',
        rules: 'list_of_integer',
        change: (value: string) => (this.initialSimphonyVelues.excludedTenders = value && value !== '' ? value : null),
      },
      {
        name: 'useExternalId',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.useExternalId',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.useExternalId = value),
      },
      {
        name: 'useSanifairAsTender',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.useSanifairAsTender',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.useSanifairAsTender = value),
      },
      {
        name: 'useTableAsEmployee',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.useTableAsEmployee',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.useTableAsEmployee = value),
      },
      {
        name: 'useCodeAsOrderCode',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.useCodeAsOrderCode',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.useCodeAsOrderCode = value),
      },
      {
        name: 'useSecondaryCodeAsOrdercode',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.useSecondaryCodeAsOrdercode',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.useSecondaryCodeAsOrdercode = value),
      },
      {
        name: 'firetimeEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.firetimeEnabled',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.firetimeEnabled = value),
      },
      {
        name: 'sendOrderNotes',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.sendOrderNotes',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.sendOrderNotes = value),
      },
      {
        name: 'hideTableName',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.hideTableName',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.hideTableName = value),
      },
      {
        name: 'healthcheckEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.healthcheckEnabled',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.healthcheckEnabled = value),
      },
      {
        name: 'multiConventional',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.multiConventional',
        rules: '',
        change: (value: boolean) => (this.initialSimphonyVelues.multiConventional = value),
      },
      {
        name: 'useCancelTenderType',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.useCancelTenderType',
        change: (value: boolean) => (this.initialSimphonyVelues.useCancelTenderType = value),
      },
      {
        name: 'sentBoardingPassEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.sentBoardingPassEnabled',
        change: (value: boolean) => (this.initialSimphonyVelues.sentBoardingPassEnabled = value),
      },
      {
        name: 'sentRbiLoyaltyEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.sentRbiLoyaltyEnabled',
        change: (value: boolean) => (this.initialSimphonyVelues.sentRbiLoyaltyEnabled = value),
      },
      {
        name: 'recalculatePayment',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.recalculatePayment',
        change: (value: boolean) => (this.initialSimphonyVelues.recalculatePayment = value),
      },
      {
        name: 'replaceCashOrdersByVirtualOrders',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.replaceCashOrdersByVirtualOrders',
        change: (value: boolean) => (this.initialSimphonyVelues.replaceCashOrdersByVirtualOrders = value),
      },
      {
        name: 'autoSyncEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.autoSyncEnabled',
        change: (value: boolean) => (this.initialSimphonyVelues.autoSyncEnabled = value),
      },
      {
        name: 'priceSequence',
        type: InputType.Text,
        default: 1,
        label: 'venue.form.checkoutIntegrationProps.priceSequence',
        rules: 'integer',
        change: (value: number) => (this.initialSimphonyVelues.priceSequence = value),
      },
      {
        name: 'multiConventionalRevenueCenters',
        type: InputType.Text,
        default: null,
        label: 'venue.form.checkoutIntegrationProps.multiConventionalRevenueCenters',
        rules: 'list_of_integer',
        change: (value: string) =>
          (this.initialSimphonyVelues.multiConventionalRevenueCenters = value && value !== '' ? value : null),
      },
    ];
  }

  get lightspeedKSeriesProps() {
    return [
      {
        name: 'businessLocationId',
        type: InputType.Text,
        label: 'Business Location Id',
        rules: 'required',
      },
      {
        name: 'businessId',
        type: InputType.Text,
        label: 'Business Id',
        rules: 'required',
      },
      {
        name: 'menus',
        type: InputType.Combobox,
        label: 'Menus',
        multiple: true,
        default: [],
      },
      {
        name: 'deliveryArticleSku',
        type: InputType.Text,
        label: 'Delivery Fee Article SKU',
      },
      {
        name: 'inside',
        type: InputType.Text,
        label: 'Account/Order Profile (Inside)',
      },
      {
        name: 'takeAway',
        type: InputType.Text,
        label: 'Account/Order Profile (take away)',
      },
      {
        name: 'delivery',
        type: InputType.Text,
        label: 'Account/Order Profile (delivery)',
      },
      {
        name: 'woltTakeAway',
        type: InputType.Text,
        label: 'Wolt Account/Order Profile (take away)',
      },
      {
        name: 'woltDelivery',
        type: InputType.Text,
        label: 'Wolt Account/Order Profile (delivery)',
      },
      {
        name: 'uberEatsTakeAway',
        type: InputType.Text,
        label: 'Uber Account/Order Profile (take away)',
      },
      {
        name: 'uberEatsDelivery',
        type: InputType.Text,
        label: 'Uber Account/Order Profile (delivery)',
      },
      {
        name: 'jetTakeAway',
        type: InputType.Text,
        label: 'JET Account/Order Profile (take away)',
      },
      {
        name: 'jetDelivery',
        type: InputType.Text,
        label: 'JET Account/Order Profile (delivery)',
      },
      {
        name: 'creditCard',
        type: InputType.Text,
        label: 'Payment Method Mapping (credit card)',
      },
      {
        name: 'pos',
        type: InputType.Text,
        label: 'Payment Method Mapping (pos)',
      },
      {
        name: 'cash',
        type: InputType.Text,
        label: 'Payment Method Mapping (cash)',
      },
      {
        name: 'applePay',
        type: InputType.Text,
        label: 'Payment Method Mapping (apple pay)',
      },
      {
        name: 'googlePay',
        type: InputType.Text,
        label: 'Payment Method Mapping (google pay)',
      },
      {
        name: 'paypal',
        type: InputType.Text,
        label: 'Payment Method Mapping (paypal)',
      },
      {
        name: 'boncard',
        type: InputType.Text,
        label: 'Payment Method Mapping (wallee/boncard)',
      },
      {
        name: 'sepa',
        type: InputType.Text,
        label: 'Payment Method Mapping (SEPA)',
      },
      {
        name: 'ec',
        type: InputType.Text,
        label: 'Payment Method Mapping (EC)',
      },
      {
        name: 'girocard',
        type: InputType.Text,
        label: 'Payment Method Mapping (Girocard)',
      },
      {
        name: 'mastercard',
        type: InputType.Text,
        label: 'Payment Method Mapping (Mastercard)',
      },
      {
        name: 'amex',
        type: InputType.Text,
        label: 'Payment Method Mapping (Amex)',
      },
      {
        name: 'visa',
        type: InputType.Text,
        label: 'Payment Method Mapping (VISA)',
      },
      {
        name: 'visaE',
        type: InputType.Text,
        label: 'Payment Method Mapping (Visa-E)',
      },
      {
        name: 'vPay',
        type: InputType.Text,
        label: 'Payment Method Mapping (V-Pay)',
      },
      {
        name: 'jcb',
        type: InputType.Text,
        label: 'Payment Method Mapping (JCB)',
      },
      {
        name: 'maestro',
        type: InputType.Text,
        label: 'Payment Method Mapping (Maestro)',
      },
      {
        name: 'wolt',
        type: InputType.Text,
        label: 'Payment Method Mapping (Wolt)',
      },
      {
        name: 'uberEats',
        type: InputType.Text,
        label: 'Payment Method Mapping (Uber-Eats)',
      },
      {
        name: 'jet',
        type: InputType.Text,
        label: 'Payment Method Mapping (Jet)',
      },
      {
        name: 'preorderInside',
        type: InputType.Text,
        label: 'Price Mapping (Preorder Inside)',
      },
      {
        name: 'preorderTakeAway',
        type: InputType.Text,
        label: 'Price Mapping (Preorder TakeAway)',
      },
      {
        name: 'preorderDelivery',
        type: InputType.Text,
        label: 'Price Mapping (Preorder Delivery)',
      },
      {
        name: 'terminalInside',
        type: InputType.Text,
        label: 'Price Mapping (Terminal Inside)',
      },
      {
        name: 'terminalTakeAway',
        type: InputType.Text,
        label: 'Price Mapping (Terminal TakeAway)',
      },
      {
        name: 'woltPrice',
        type: InputType.Text,
        label: 'Price Mapping (Wolt)',
      },
      {
        name: 'uberEatsPrice',
        type: InputType.Text,
        label: 'Price Mapping (Uber-Eats)',
      },
      {
        name: 'jetPrice',
        type: InputType.Text,
        label: 'Price Mapping (Jet)',
      },
      {
        name: 'firetime',
        type: InputType.Decimal,
        default: null,
        label: 'Firetime',
      },
      {
        name: 'posFlowEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'Pos Flow Enabled',
      },
      {
        name: 'multiConventional',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.checkoutIntegrationProps.multiConventional',
      },
    ];
  }

  get vectronItemsProps() {
    return [
      {
        name: 'clientId',
        type: InputType.Text,
        label: 'Client Id',
        rules: 'required',
      },
      {
        name: 'url',
        type: InputType.Text,
        label: 'Url',
        rules: 'required',
      },
    ];
  }

  get tableItemProps() {
    return [
      {
        name: 'uuid',
        value: 'uuid',
        default: uuid(),
        disabled: true,
        key: uuid(),
      },
      {
        name: 'table',
        type: InputType.Select,
        default: 'none',
        label: 'Table',
        items: this.tableItems,
        rules: 'required',
      },
      {
        name: 'name',
        type: InputType.Text,
        label: 'Name',
        rules: 'required',
      },
      {
        name: 'channelInside',
        type: InputType.Text,
        label: 'Channel Inside',
        rules: 'required',
      },
      {
        name: 'channelTakeaway',
        type: InputType.Text,
        label: 'Channel Takeaway',
        rules: 'required',
      },
      {
        name: 'channelDelivery',
        type: InputType.Text,
        label: 'Channel Delivery',
        rules: 'required',
      },
    ];
  }

  get tendersItemProps() {
    return [
      {
        name: 'uuid',
        value: 'uuid',
        disabled: true,
        default: uuid(),
        key: uuid(),
      },
      {
        name: 'paymentMethod',
        type: InputType.Select,
        default: 'none',
        label: 'Payment Method',
        items: this.paymentMethods,
        rules: 'required',
      },
      {
        name: 'channelInside',
        type: InputType.Text,
        label: 'Channel Inside',
        rules: 'required',
      },
      {
        name: 'channelTakeaway',
        type: InputType.Text,
        label: 'Channel Takeaway',
        rules: 'required',
      },
      {
        name: 'channelDelivery',
        type: InputType.Text,
        label: 'Channel Delivery',
        rules: 'required',
      },
    ];
  }

  get simphonyTendersItemProps() {
    return [
      {
        name: 'uuid',
        value: 'uuid',
        disabled: true,
        default: uuid(),
        key: uuid(),
      },
      {
        name: 'name',
        type: InputType.Text,
        label: 'Name',
        rules: 'required',
      },
      {
        name: 'tenderId',
        type: InputType.Text,
        label: 'Tender Id',
        rules: 'required',
      },
      {
        name: 'paymentMethod',
        type: InputType.Combobox,
        default: 'none',
        label: 'Payment Method',
        items: this.paymentMethods,
        rules: 'required',
        clearable: true,
      },
    ];
  }

  get hellotessTendersItemProps() {
    return [
      {
        name: 'uuid',
        value: 'uuid',
        disabled: true,
        default: uuid(),
        key: uuid(),
      },
      {
        name: 'name',
        type: InputType.Text,
        label: 'Name',
        rules: 'required',
      },
      {
        name: 'tenderId',
        type: InputType.Text,
        label: 'Tender Id',
        rules: 'required',
      },
      {
        name: 'paymentMethod',
        type: InputType.Combobox,
        default: 'none',
        label: 'Payment Method',
        items: this.paymentMethods,
        rules: 'required',
        clearable: true,
      },
    ];
  }

  public ciSettingsDisabledCallback(form: { checkoutIntegration: string }) {
    return form.checkoutIntegration === 'none';
  }

  public printerSettingsDisabledCallback(form: { hasPrinter: boolean }) {
    return !form.hasPrinter;
  }

  get checkoutIntegrationTypes() {
    return [
      { value: 'none', text: this.$t('common.none') },
      { value: 'antara', text: 'Antara' },
      { value: 'hypersoft', text: 'Hypersoft' },
      { value: 'matrix', text: 'Matrix' },
      { value: 'food_genius', text: 'Food Genius' },
      { value: 'omnivore', text: 'Omnivore' },
      { value: 'hellotess', text: 'helloTESS!' },
      { value: 'deliverect', text: 'Deliverect' },
      { value: 'gastrofix', text: 'Gastrofix' },
      { value: 'simply_delivery', text: 'Simply Delivery' },
      { value: 'polynorm', text: 'Polynorm' },
      { value: 'simphony', text: 'Simphony' },
      { value: 'lightspeed_k_series', text: 'Lightspeed K Series' },
      { value: 'vectron', text: 'Vectron' },
      { value: 'lina_local', text: 'Lina local' },
      { value: 'lina_web', text: 'Lina web' },
    ];
  }

  get orderStatusTypes() {
    return [
      { value: 'none', text: this.$t('orderStatus.none') },
      { value: 'created', text: this.$t('orderStatus.created') },
      { value: 'in_preparation', text: this.$t('orderStatus.in_preparation') },
      { value: 'ready', text: this.$t('orderStatus.ready') },
      { value: 'done', text: this.$t('orderStatus.done') },
      { value: 'in_payment', text: this.$t('orderStatus.in_payment') },
      { value: 'awaiting_confirmation', text: this.$t('orderStatus.awaiting_confirmation') },
    ];
  }

  get paymentMethods() {
    return [
      { value: PaymentMethod.CREDIT_CARD, text: 'Credit card' },
      { value: PaymentMethod.BALANCE, text: 'Balance' },
      { value: PaymentMethod.CASH, text: 'Cash' },
      { value: PaymentMethod.POS, text: 'Pos' },
      { value: PaymentMethod.SEPA, text: 'Sepa' },
      { value: PaymentMethod.APPLE_PAY, text: 'Apple pay' },
      { value: PaymentMethod.GOOGLE_PAY, text: 'Google pay' },
      { value: PaymentMethod.PAYPAL, text: 'Paypal' },
      { value: PaymentMethod.SOFORTBANKING, text: 'Sofortbanking' },
      { value: PaymentMethod.WALLEE, text: 'Walle' },
      { value: PaymentMethod.POSTFINANCE, text: 'Postfinance' },
      { value: PaymentMethod.POSTFINANCE_CARD, text: 'Postfinance Card' },
      { value: PaymentMethod.REKA, text: 'Reka' },
      { value: PaymentMethod.TWINT, text: 'Twint' },
      { value: PaymentMethod.LUNCHECK, text: 'Luncheck' },
      { value: PaymentMethod.BONCARD, text: 'Boncard' },
      { value: PaymentMethod.ALIPAY, text: 'Alipay' },
      { value: PaymentMethod.WECHAT, text: 'Wechat' },
      { value: PaymentMethod.CARD_WORLDLINE, text: 'Wordline' },
      { value: PaymentMethod.TAC, text: 'Tac' },
      { value: PaymentMethod.CCV, text: 'Ccv' },
      { value: PaymentMethod.ZERO_PAYMENT, text: 'Zero Payment' },
      { value: PaymentMethod.EC, text: 'Ec' },
      { value: PaymentMethod.GIROCARD, text: 'Girocard' },
      { value: PaymentMethod.MASTERCARD, text: 'Mastercard' },
      { value: PaymentMethod.AMEX, text: 'Amex' },
      { value: PaymentMethod.VISA, text: 'Visa' },
      { value: PaymentMethod.VISA_E, text: 'Visa e' },
      { value: PaymentMethod.VPAY, text: 'Vpay' },
      { value: PaymentMethod.JCB, text: 'Jcb' },
      { value: PaymentMethod.MAESTRO, text: 'Maestro' },
      { value: PaymentMethod.DINERS, text: 'Diners' },
      { value: PaymentMethod.DISCOVER, text: 'Discover' },
      { value: PaymentMethod.SUBSIDY, text: 'Subsidy' },
      { value: PaymentMethod.OTHER, text: 'Other' },
    ];
  }

  get tableItems() {
    return this.tablesItem.map((table: any) => {
      return {
        text: table.number,
        value: table._id,
      };
    });
  }

  get lightSpeed() {
    return this.checkoutIntegration && this.checkoutIntegration.lightspeed
      ? {
          ...this.checkoutIntegration.lightspeed,
          ...this.checkoutIntegration.lightspeed.orderProfiles,
          ...this.checkoutIntegration.lightspeed.paymentMethodMappings,
          preorderInside: this.checkoutIntegration.lightspeed.priceMappings?.preorder?.inside,
          preorderTakeAway: this.checkoutIntegration.lightspeed.priceMappings?.preorder?.takeAway,
          preorderDelivery: this.checkoutIntegration.lightspeed.priceMappings?.preorder?.delivery,
          terminalInside: this.checkoutIntegration.lightspeed.priceMappings?.terminal?.inside,
          terminalTakeAway: this.checkoutIntegration.lightspeed.priceMappings?.terminal?.takeAway,
          woltPrice: this.checkoutIntegration.lightspeed.priceMappings?.wolt,
          uberEatsPrice: this.checkoutIntegration.lightspeed.priceMappings?.uberEats,
          jetPrice: this.checkoutIntegration.lightspeed.priceMappings?.jet,
        }
      : {};
  }

  public async onConfChange(type: string) {
    this.typeConfiguration = type;
  }

  public getData() {
    return { ...this.$refs.form.form };
  }

  public addTender(table: Table, indexTable: number, indexTender: number) {
    if (this.$refs.tenderProps && this.$refs.tenderProps.length) {
      const handledTable = this.$refs.tableProps.find((vueComponent: any) => vueComponent.form.uuid === table.uuid);

      this.tables[indexTable] = {
        ...handledTable.form,
        tenders: [...this.tables[indexTable].tenders],
      };

      this.tables[indexTable].tenders.push({
        paymentMethod: '',
        channelDelivery: '',
        channelInside: '',
        channelTakeaway: '',
        uuid: uuid(),
      });
      this.$forceUpdate();
    }
  }

  public initTable() {
    this.tables.push({
      name: '',
      table: '',
      channelDelivery: '',
      channelInside: '',
      channelTakeaway: '',
      uuid: uuid(),
      tenders: [],
    });
  }

  public initTender(table: Table, indexTable: number) {
    if (this.$refs.tableProps && this.$refs.tableProps.length) {
      const handledTable = this.$refs.tableProps.find((vueComponent: any) => vueComponent.form.uuid === table.uuid);

      this.tables[indexTable] = {
        ...handledTable.form,
        tenders: [
          {
            paymentMethod: '',
            channelDelivery: '',
            channelInside: '',
            channelTakeaway: '',
            uuid: uuid(),
          },
        ],
      };
    }
    this.$forceUpdate();
  }

  public addTable(indexTable: number) {
    if (this.$refs.tableProps && this.$refs.tableProps.length) {
      this.tables[indexTable] = {
        ...this.$refs.tableProps[this.$refs.tableProps.length - 1]?.form,
        tenders: [...this.tables[indexTable].tenders],
      };
      this.tables.push({
        name: '',
        table: '',
        channelDelivery: '',
        channelInside: '',
        channelTakeaway: '',
        uuid: uuid(),
        tenders: [],
      });
    }
  }

  public removeTable(index: number) {
    this.tables[this.tables.length - 1] = {
      ...this.$refs.tableProps[this.$refs.tableProps.length - 1]?.form,
      tenders: [...this.tables[this.tables.length - 1].tenders],
    };
    this.tables.splice(index, 1);
  }

  public removeTender(indexTable: number, indexTender: number) {
    this.tables[indexTable].tenders[this.tables[indexTable].tenders.length - 1] = {
      ...this.$refs.tenderProps[this.$refs.tenderProps.length - 1]?.form,
    };
    this.tables[indexTable].tenders.splice(indexTender, 1);
    this.$forceUpdate();
  }

  public initSimphonyTender() {
    this.simphonyTenders.push({
      name: '',
      paymentMethod: '',
      tenderId: 0,
      uuid: uuid(),
    });
  }

  public removeSimphonyTender(index: number) {
    this.simphonyTenders.splice(index, 1);
  }

  public initHellotessTender() {
    this.hellotessTenders.push({
      name: '',
      paymentMethod: '',
      tenderId: '',
      uuid: uuid(),
    });
  }

  public removeHellotessTender(index: number) {
    this.hellotessTenders.splice(index, 1);
  }

  public syncLightspeedFoodcard() {
    this.$startLoading('lightspeed.foodcard');
    const api: LightspeedApiService = new LightspeedApiService();
    api
      .syncFoodcard({ venue: this.$route.params.id || '' })
      .then(() => {
        this.notifySuccess('lightspeed.notification.foodcardSyncSuccess');
        this.$stopLoading('lightspeed.foodcard');
      })
      .catch((err: Error) => {
        console.error(err);
        this.notifyError('lightspeed.notification.foodcardSyncError');
        this.$stopLoading('lightspeed.foodcard');
      });
  }

  public updateOnlySyncLightspeedFoodcard() {
    this.$startLoading('lightspeed.foodcard');
    const api: LightspeedApiService = new LightspeedApiService();
    api
      .syncFoodcard({ venue: this.$route.params.id || '', updateOnly: true })
      .then(() => {
        this.notifySuccess('lightspeed.notification.foodcardSyncSuccess');
        this.$stopLoading('lightspeed.foodcard');
      })
      .catch((err: Error) => {
        console.error(err);
        this.notifyError('lightspeed.notification.foodcardSyncError');
        this.$stopLoading('lightspeed.foodcard');
      });
  }

  public addOnlySyncLightspeedFoodcard() {
    this.$startLoading('lightspeed.foodcard');
    const api: LightspeedApiService = new LightspeedApiService();
    api
      .syncFoodcard({ venue: this.$route.params.id || '', addOnly: true })
      .then(() => {
        this.notifySuccess('lightspeed.notification.foodcardSyncSuccess');
        this.$stopLoading('lightspeed.foodcard');
      })
      .catch((err: Error) => {
        console.error(err);
        this.notifyError('lightspeed.notification.foodcardSyncError');
        this.$stopLoading('lightspeed.foodcard');
      });
  }

  public addPrice() {
    this.prices.push({
      orderProfile: '',
      value: '',
    });
  }

  public removePrice(item: PriceType) {
    this.prices.splice(this.prices.indexOf(item), 1);
  }

  public syncSimphonyFoodcard(mode: SimphonySyncMode, success: string, error: string) {
    this.$startLoading('simphony.foodcard');
    const api: SimphonyApiService = new SimphonyApiService();
    api
      .syncFoodcard({ venue: this.$route.params.id || '', mode })
      .then(() => {
        this.notifySuccess(success);
        this.$stopLoading('simphony.foodcard');
      })
      .catch((err: Error) => {
        console.error(err);
        this.notifyError(error);
        this.$stopLoading('simphony.foodcard');
      });
  }

  public syncLinaPrices() {
    this.$startLoading('lina.prices');
    const api: SyncPricesApiService = new SyncPricesApiService();
    api
      .syncPrices({
        venue: this.$route.params.id || '',
        checkoutIntegration: this.typeConfiguration,
        mode: 'default',
      })
      .then(() => {
        this.notifySuccess('lina.notification.pricesSyncSuccess');
        this.$stopLoading('lina.prices');
      })
      .catch((err: Error) => {
        console.error(err);
        this.notifyError('lina.notification.pricesSyncError');
        this.$stopLoading('lina.prices');
      });
  }

  private prepareSimphonyProps() {
    if (this.$refs.simphonyProps && this.$refs.simphonyProps.form) {
      const simphonyProps = { ...this.$refs.simphonyProps.form };
      Object.keys(simphonyProps).map((key: string) => {
        if (simphonyProps[key as keyof SimphonyIntegration] === '') {
          simphonyProps[key] = null;
        }
      });

      if (simphonyProps.excludedTenders && simphonyProps.excludedTenders !== '') {
        simphonyProps.excludedTenders = simphonyProps.excludedTenders.split(',');
      }

      if (simphonyProps.multiConventionalRevenueCenters && simphonyProps.multiConventionalRevenueCenters !== '') {
        simphonyProps.multiConventionalRevenueCenters = simphonyProps.multiConventionalRevenueCenters.split(',');
      }

      return simphonyProps;
    } else {
      return {};
    }
  }

  public getIntegrationByType() {
    const venue: Venue = this.initialValues as Venue;

    if (venue?.checkoutIntegration === ANTARA) {
      return this.checkoutIntegration?.antara;
    } else if (venue?.checkoutIntegration === HELLOTESS) {
      return this.checkoutIntegration?.hellotess;
    } else if (venue?.checkoutIntegration === GASTROFIX) {
      return this.checkoutIntegration?.gastrofix;
    } else if (venue?.checkoutIntegration === LINA_LOCAL) {
      return this.checkoutIntegration?.linaLocal;
    } else if (venue?.checkoutIntegration === LINA_WEB) {
      return this.checkoutIntegration?.linaWeb;
    }

    return {};
  }

  public async isValidIntegrationProps(): Promise<{ refKey: any; result: boolean }> {
    if (this.typeConfiguration !== this.deliverect) return { refKey: null, result: true };
    return this.isValidWithKey(await this.validateRefs(this.$refs.tableProps));
  }

  public getIntegrationProperties() {
    const integrationProps = {
      ...(this.$refs.integrationProps && this.$refs.integrationProps.form ? this.$refs.integrationProps.form : null),
    };

    if (!integrationProps) return {};

    if (this.typeConfiguration === this.antara) {
      let generalProps: any = {};
      generalProps.ip = integrationProps?.ip;
      generalProps.port = integrationProps?.port;
      generalProps.storeId = integrationProps?.storeId;
      generalProps.scheduleSync = integrationProps?.scheduleSync;
      generalProps.lastResult = integrationProps?.lastResult;
      return {
        antara: { ...generalProps },
      };
    } else if (this.typeConfiguration === this.deliverect) {
      const tables = this.tables.map((table: Table) => {
        return {
          ...this.$refs.tableProps.find((item: any) => item.form.uuid === table.uuid).form,
          tenders: table.tenders.map(
            (tender: Tender) => this.$refs.tenderProps.find((item: any) => item.form.uuid === tender.uuid).form,
          ),
        };
      });

      const deliverectProps = {
        ...(this.$refs.deliverectProps && this.$refs.deliverectProps.form ? this.$refs.deliverectProps.form : null),
      };

      return {
        deliverect: {
          tables,
          ...deliverectProps,
        },
      };
    } else if (this.typeConfiguration === this.gastrofix) {
      return {
        gastrofix: {
          gastrofixApiKey: integrationProps.gastrofixApiKey,
          gastrofixBusinessUnit: integrationProps.gastrofixBusinessUnit,
        },
      };
    } else if (this.typeConfiguration === this.hellotess) {
      return {
        hellotess: {
          useV2: integrationProps.useV2,
          storeId: integrationProps.storeId,
          paymentMethod: integrationProps.paymentMethod,
          hellotessApiBaseUrl: integrationProps.hellotessApiBaseUrl,
          hellotessApiKey: integrationProps.hellotessApiKey,
          tenders: this.$refs.hellotessTenderProps?.map(
            (tenderRef: {
              form: {
                name: string;
                tenderId: string;
                paymentMethod: string | { name: string; value: string };
                uuid?: string;
              };
            }) => {
              if (tenderRef && tenderRef.form && typeof tenderRef.form.paymentMethod === 'object') {
                tenderRef.form.paymentMethod = tenderRef.form.paymentMethod?.value;
              }
              delete tenderRef?.form?.uuid;
              return tenderRef?.form;
            },
          ),
        },
      };
    } else if (this.typeConfiguration === this.simphony) {
      return {
        simphony: {
          ...this.prepareSimphonyProps(),
          tenders: this.$refs.simphonyTenderProps?.map(
            (tenderRef: {
              form: {
                name: string;
                tenderId: number;
                paymentMethod: string | { name: string; value: string };
                uuid?: string;
              };
            }) => {
              if (tenderRef && tenderRef.form && typeof tenderRef.form.paymentMethod === 'object') {
                tenderRef.form.paymentMethod = tenderRef.form.paymentMethod?.value;
              }
              delete tenderRef?.form?.uuid;
              return tenderRef?.form;
            },
          ),
        },
      };
    } else if (this.typeConfiguration === this.lightspeedKSeries) {
      return {
        lightspeed: {
          ...(this.$refs.lightspeedProps && this.$refs.lightspeedProps.form ? this.$refs.lightspeedProps.form : {}),
          orderProfiles: {
            inside: this.$refs.lightspeedProps?.form?.inside,
            takeAway: this.$refs.lightspeedProps?.form?.takeAway,
            delivery: this.$refs.lightspeedProps?.form?.delivery,
            woltTakeAway: this.$refs.lightspeedProps?.form?.woltTakeAway,
            woltDelivery: this.$refs.lightspeedProps?.form?.woltDelivery,
            uberEatsTakeAway: this.$refs.lightspeedProps?.form?.uberEatsTakeAway,
            uberEatsDelivery: this.$refs.lightspeedProps?.form?.uberEatsDelivery,
            jetTakeAway: this.$refs.lightspeedProps?.form?.jetTakeAway,
            jetDelivery: this.$refs.lightspeedProps?.form?.jetDelivery,
          },
          paymentMethodMappings: {
            creditCard: this.$refs.lightspeedProps?.form?.creditCard,
            pos: this.$refs.lightspeedProps?.form?.pos,
            cash: this.$refs.lightspeedProps?.form?.cash,
            applePay: this.$refs.lightspeedProps?.form?.applePay,
            googlePay: this.$refs.lightspeedProps?.form?.googlePay,
            paypal: this.$refs.lightspeedProps?.form?.paypal,
            boncard: this.$refs.lightspeedProps?.form?.boncard,
            wolt: this.$refs.lightspeedProps?.form?.wolt,
            jet: this.$refs.lightspeedProps?.form?.jet,
            uberEats: this.$refs.lightspeedProps?.form?.uberEats,
            sepa: this.$refs.lightspeedProps?.form?.sepa,
            ec: this.$refs.lightspeedProps?.form?.ec,
            girocard: this.$refs.lightspeedProps?.form?.girocard,
            mastercard: this.$refs.lightspeedProps?.form?.mastercard,
            amex: this.$refs.lightspeedProps?.form?.amex,
            visa: this.$refs.lightspeedProps?.form?.visa,
            visaE: this.$refs.lightspeedProps?.form?.visaE,
            vPay: this.$refs.lightspeedProps?.form?.vPay,
            maestro: this.$refs.lightspeedProps?.form?.maestro,
          },
          priceMappings: {
            preorder: {
              inside: this.$refs.lightspeedProps?.form?.preorderInside,
              takeAway: this.$refs.lightspeedProps?.form?.preorderTakeAway,
              delivery: this.$refs.lightspeedProps?.form?.preorderDelivery,
            },
            terminal: {
              inside: this.$refs.lightspeedProps?.form?.terminalInside,
              takeAway: this.$refs.lightspeedProps?.form?.terminalTakeAway,
            },
            wolt: this.$refs.lightspeedProps?.form?.woltPrice,
            uberEats: this.$refs.lightspeedProps?.form?.uberEatsPrice,
            jet: this.$refs.lightspeedProps?.form?.jetPrice,
            prices: this.prices,
          },
        },
      };
    } else if (this.typeConfiguration === this.vectron) {
      return {
        vectron: {
          ...this.$refs.vectronProps.form,
        },
      };
    }
  }
}
