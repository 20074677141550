
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Company as CompanyModel } from '@/interfaces/models/Company';
import CompanyTable from '@/components/company/CompanyTable.vue';
import { Venue } from '@/interfaces/models/Venue';
import { Page } from '@/interfaces/api/Page';

const app = namespace('app');
const company = namespace('company');
const venue = namespace('venue');

@Component({
  components: { CompanyTable, VWrapper },
})
export default class Company extends Vue {
  @venue.State('active') public venue!: Venue;
  @app.State('venues') public venues!: Venue[];
  @company.Action('getCompaniesByVenue') public getCompaniesByVenue!: any;
  @company.Action('destroy') public deleteCompany!: any;
  @company.State('items') public companies!: CompanyModel[];
  @company.State((state) => state.pagination.total) public total!: number;
  @company.Action('setFilter') public setFilter!: any;

  public async mounted() {
    if (!this.venue?._id) return;

    this.$startLoading('company');
    this.setFilter({ page: 1, limit: 10 });
    await this.getCompaniesByVenue({ id: this.venue._id });
    this.$stopAllLoading();
  }

  public async onPageChange(page: Page) {
    if (page) {
      this.$startLoading('company');
      this.setFilter({ ...page });
      await this.getCompaniesByVenue({ id: this.venue._id });
      this.$stopAllLoading();
    }
  }

  public async destroy(company: CompanyModel) {
    if (!company?._id) return;
    this.$startLoading('company');
    await this.deleteCompany({ id: company._id });
    this.$stopLoading('company');
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue?._id && !this.$isLoading('company')) {
      this.$startLoading('company');
      this.setFilter({ page: 1, limit: 10 });
      await this.getCompaniesByVenue({ id: this.venue._id });
      this.$stopLoading('company');
    }
  }
}
