import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { UiComponent } from '@/interfaces/models/UiComponent';
import { UiComponentType } from '@/enums/UiComponentType';
import UiComponentApiService from '@/api/http/UiComponentApiService';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';

interface UiComponentState {
  items: UiComponent[];
  active: UiComponent | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: UiComponentApiService = new UiComponentApiService();

const state: UiComponentState = {
  items: [],
  active: null,
  filter: {},
  pagination: {
    total: 10,
  },
};

const actions: ActionTree<UiComponentState, RootState> = {
  fetch({ commit, state }, data: { type: UiComponentType; page: Page }) {
    return api
      .getUiComponents(data.type, data.page, state.filter)
      .then((res: AxiosResponse<UiComponent[] | Pagination<UiComponent>>) => {
        const resData = res.data;
        commit('setItems', res.data);
      });
  },
  store({ commit }, data: { type: UiComponentType; data: Partial<UiComponent> }) {
    return api.storeUiComponent(data).then((res: AxiosResponse<UiComponent>) => {
      commit('addItem', res.data);
    });
  },
  show({ commit }, data: { type: UiComponentType; id: string }) {
    return api.showUiComponent(data).then((res: AxiosResponse<UiComponent>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: { type: UiComponentType; data: Partial<UiComponent>; id: string }) {
    return api.updateUiComponent(data).then((res: AxiosResponse<UiComponent>) => {
      commit('update', res.data);
    });
  },
  delete({ commit }, data: { type: UiComponentType; id: string }) {
    return api.deleteUiComponent(data).then(() => {
      commit('delete', { id: data.id });
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<UiComponentState> = {
  setItems(state: UiComponentState, data: Pagination<UiComponent>) {
    state.items = (data.data as UiComponent[]) || data;

    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setActive(state: UiComponentState, data: UiComponent) {
    state.active = data;
  },
  addItem(state: UiComponentState, data: UiComponent) {
    state.items.push(data);
  },
  update(state: UiComponentState, data: UiComponent) {
    state.items = [...state.items.filter((r: UiComponent) => r._id !== data._id), data];
  },
  delete(state: UiComponentState, data: { id: string }) {
    state.items = [...state.items.filter((r: UiComponent) => r._id !== data.id)];
  },
  setFilter(state: UiComponentState, data: Filter) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
