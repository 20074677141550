import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { SimphonySyncMode } from '@/enums/SimphonySyncMode';

export default class SimphonyApiService extends ApiService<any> {
  constructor() {
    super('simphony', 'v2');
  }

  public syncFoodcard(payload: { venue: string; mode: SimphonySyncMode }): AxiosPromise<void> {
    return axios.put(`${this.getBaseUrl()}/foodcards/venues/${payload.venue}/sync`, { mode: payload.mode });
  }
}
