
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CustomerGroup as CustomerGroupEnum } from '@/enums/CustomerGroup';
import { Venue } from '@/interfaces/models/Venue';

const venue = namespace('venue');

@Component
export default class UiComponentFilter extends Vue {
  @Prop({ type: Array, required: true }) public customerGroups!: CustomerGroupEnum[];
  @venue.State('active') public venue!: Venue;

  public selectedCustomerGroup: string = '';

  public mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    } else {
      this.setDefaultCustomerGroup();
    }
  }

  public setDefaultCustomerGroup() {
    if (this.venue.customerGroup) {
      this.selectedCustomerGroup = this.venue.customerGroup || '';
    }
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id) {
      this.setDefaultCustomerGroup();
    }
  }

  @Watch('selectedCustomerGroup')
  @Emit('filter')
  public onFilterChange() {
    return { customerGroup: this.selectedCustomerGroup };
  }
}
