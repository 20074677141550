
import { Component, Prop, Model, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import { DeliveryArea } from '@/interfaces/models/delivery/DeliveryArea';
import { Polygon } from '@/interfaces/models/delivery/Polygon';
import StackedForm from '@/mixins/StackedForm';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import VPolygonMap from '@/components/shared/VPolygonMap.vue';
import VenueApiService from '@/api/http/VenueApiService';

const api = new VenueApiService();

@Component({
  components: {
    VFormBuilder,
    VPolygonMap,
  },
})
export default class DeliveryFeesAreasForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true, default: [] }) public deliveryAreas!: DeliveryArea[];
  @Model() public minValues!: { mov: string; fee: string; area: string };

  public deliveryAreasList: DeliveryArea[] = [...this.deliveryAreas];
  public areaColors: string[] = [];

  public $refs!: {
    areaFileForm: InstanceType<typeof VFormBuilder> & { form: any };
  };

  public mounted(): void {
    this.update();
  }

  get currency() {
    // @ts-ignore
    return this.initialValues ? this.initialValues.currency : 'EUR';
  }

  get kmlItems() {
    return [
      {
        name: 'file',
        type: InputType.File,
        accept: '.kml',
        default: null,
        label: 'venue.form.kmlUpload',
        change: this.onFileUpload,
      },
    ];
  }

  get movDefault() {
    // @ts-ignore
    return (this.initialValues && this.initialValues.mov) || '0.00';
  }

  get areasMapData() {
    return {
      coordinates: this.deliveryAreasList.map((area) => this.convertCoordinates(area.location)),
      polygonsColorsRGB: this.areaColors,
    };
  }

  public generateRGBColors() {
    const o = Math.round;
    const r = Math.random;
    const s = 255;

    return `${o(r() * s)}, ${o(r() * s)}, ${o(r() * s)}`;
  }

  public setColors() {
    const defaultColors = [
      '60, 8, 140',
      '138, 44, 140',
      '255, 0, 0',
      '255, 255, 0',
      '255, 68, 51',
      '0, 128, 0',
      '176, 224, 230',
      '196, 164, 132',
      '128, 128, 128',
      '94, 47, 59',
    ];

    for (const index of this.deliveryAreasList.keys()) {
      this.areaColors.push(defaultColors[index] || this.generateRGBColors());
    }
  }

  public convertCoordinates(location: Polygon) {
    if (!location) {
      return [];
    }

    const coords = location.coordinates.flat(2);
    const coordsFixed = (coords || []).map((x, i, arr) => (i % 2 ? arr[i - 1] : arr[i + 1]));

    return coordsFixed;
  }

  public async onFileUpload() {
    try {
      const data: any = { ...this.$refs.areaFileForm.form };

      let formData: FormData;

      if (data.file && data.file instanceof File) {
        formData = new FormData();
        formData.append('file', data.file);
        const response = await api.getDeliveryAreaByKml(formData);

        this.deliveryAreasList = [...this.deliveryAreasList, ...response?.data];
        this.setColors();
      }
    } catch (e) {
      console.log(e);
    }
  }

  public removeArea(area: DeliveryArea) {
    const index: number = this.deliveryAreasList.indexOf(area);

    if (index > -1) {
      this.deliveryAreasList.splice(index, 1);
      this.areaColors.splice(index, 1);
    }
  }

  public update() {
    // @ts-ignore
    if (this.initialValues && this.initialValues.deliveryAreas) {
      this.deliveryAreasList = [];

      // @ts-ignore
      for (const area of this.initialValues.deliveryAreas) {
        this.deliveryAreasList.push({
          ...area,
          mov: (area.mov && area.mov.$numberDecimal) || area.mov || this.movDefault,
          fee: (area.fee && area.fee.$numberDecimal) || area.fee || '0.00',
        });
      }

      if (this.areaColors?.length !== this.deliveryAreasList.length) {
        this.setColors();
      }
    }
  }

  @Watch('initialValues')
  public onChange() {
    this.update();
  }

  public getData(): DeliveryArea[] {
    return this.deliveryAreasList;
  }
}
