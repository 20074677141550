import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import { Payment } from '@/interfaces/models/Payment';
import { VenueInvoiceType } from '@/enums/VenueInvoiceType';
import { PayPalAuthResponse } from '@/interfaces/paypal/PayPalAuthResponse';
import { VenueTransaction, VenueTransactions } from '@/interfaces/models/VenueTransactions';
import { VenueSepa } from '@/interfaces/models/VenueSepa';
import { OrderLog } from '@/interfaces/models/OrderLog';

export default class PaymentApiService extends ApiService<any> {
  constructor() {
    super('payment', 'v3');
  }

  public index(page: Page, filter?: any): AxiosPromise<Pagination<Payment>> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    return axios.get(`${this.getBaseUrl()}/?page=${page.page}&${query}`);
  }

  public showPayment(data: { payment: string }): AxiosPromise<Payment> {
    return axios.get(`${this.getBaseUrl()}/byId/${data.payment}`);
  }

  public showOrderLogs(data: { ids: string[] }): AxiosPromise<OrderLog[]> {
    return axios.get('/v2/order/log', {
      params: data,
    });
  }

  public refund(data: { order: string; articles: string[]; isExternal: boolean }): AxiosPromise<Payment> {
    return axios.patch(`${this.getBaseUrl()}/${data.order}/refund`, {
      articles: data.articles,
      isExternal: data.isExternal,
    });
  }

  public billingPdf(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    locale?: string;
    v2?: boolean;
  }): AxiosPromise {
    return axios.get(`${this.getBaseUrl()}/invoice/${param.venue}/venue`, { params: param });
  }

  public billingCsv(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    locale?: string;
    v2?: boolean;
  }): AxiosPromise {
    return axios.get(`${this.getBaseUrl()}/invoice/${param.venue}/venue/csv`, { params: param });
  }

  public billingPdfSubway(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    locale?: string;
    v2?: boolean;
  }): AxiosPromise {
    return axios.get(`${this.getBaseUrl()}/invoice/${param.venue}/subway`, { params: param });
  }

  public billingSummaryPdf(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    locale?: string;
    v2?: boolean;
  }): AxiosPromise<{ file: string }> {
    return axios.get(`${this.getBaseUrl()}/invoice/${param.venue}/venue/summary`, { params: param });
  }

  public billingSummaryPdfSubway(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    locale?: string;
    v2?: boolean;
  }): AxiosPromise<{ file: string }> {
    return axios.get(`${this.getBaseUrl()}/invoice/${param.venue}/subway/summary`, { params: param });
  }

  public billingSummaryPlainPdf(param: {
    from: string;
    to: string;
    venue?: string | string[];
    locale?: string;
    flavor: string;
    v2?: boolean;
  }): AxiosPromise<{ file: string }> {
    return axios.get(`${this.getBaseUrl()}/invoice/${param.venue}/summary/plain`, { params: param });
  }

  public articlesOverview(param: {
    from: string;
    to: string;
    venue?: string | string[];
    locale?: string;
    v2?: boolean;
  }): AxiosPromise<{ file: string }> {
    return axios.get(`${this.getBaseUrl()}/invoice/${param.venue}/venue/articles`, { params: param });
  }

  public venueTransactions(param: { venue: string }): AxiosPromise<VenueTransactions> {
    return axios.get(`${this.getBaseUrl()}/transaction/${param.venue}`);
  }

  public addTransaction(param: { venue: string; transaction: VenueTransaction }): AxiosPromise<VenueTransactions> {
    return axios.post(`${this.getBaseUrl()}/transaction/add`, param);
  }

  public downloadInvoicePdf(param: { invoiceId: string }): AxiosPromise {
    return axios.get(`${this.getBaseUrl()}/transaction/downloadInvoicePdf/${param.invoiceId}`, {
      responseType: 'blob',
    });
  }

  public downloadOrdersPdf(param: { venue: string; transactionId: string }): AxiosPromise {
    return axios.get(`${this.getBaseUrl()}/transaction/downloadOrdersPdf/${param.venue}/${param.transactionId}`, {
      responseType: 'blob',
    });
  }

  public lastTransactionsPayable(param: { venues: string[] }): AxiosPromise<boolean> {
    return axios.get(`${this.getBaseUrl()}/transaction/venues/lastTransactionsPayable`, { params: param });
  }

  public downloadInvoice(param: { file: string }): AxiosPromise {
    return axios.get(`${this.getBaseUrl()}/invoice/${param.file}`, { responseType: 'blob' });
  }

  public sendBillomatInvoice(param: { venue: string; invoiceType: VenueInvoiceType }): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/invoice/venue/send`, null, { params: param });
  }

  public authorizePayPal(data: { venue: string }): AxiosPromise<PayPalAuthResponse> {
    return axios.get(`${this.getBaseUrl()}/paypal/${data.venue}/authorize`);
  }

  public checkPayPalOnboardingStatus(data: { venue: string }): AxiosPromise<PayPalAuthResponse> {
    return axios.get(`${this.getBaseUrl()}/paypal/${data.venue}/onboarding`);
  }

  public createSepaLink(param: { venues: string[]; payLastInvoices: boolean }): AxiosPromise<VenueSepa> {
    return axios.post(`${this.getBaseUrl()}/sepa/create`, param);
  }

  public cancelSepaMandate(param: { venues: string[] }): AxiosPromise<VenueSepa> {
    return axios.post(`${this.getBaseUrl()}/sepa/cancel`, param);
  }

  public sepaPay(param: { id: string; description: string; invoiceNumber: string }): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/sepa/pay`, param);
  }

  public sepaPayLastTransactions(param: { venues: string[] }): AxiosPromise<string[]> {
    return axios.post(`${this.getBaseUrl()}/sepa/pay/last`, param);
  }
}
