
import { Component } from 'vue-property-decorator';
import LoginForm from '../components/auth/LoginForm.vue';
import { namespace } from 'vuex-class';
import { Credentials } from '@/interfaces/auth/Credentials';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import { AxiosError } from 'axios';
import { isAxiosError } from '@/util/helper';

const auth = namespace('auth');
const app = namespace('app');

@Component({
  components: { LoginForm },
})
export default class Start extends mixins(Notification) {
  @auth.Action('login') public doLogin!: any;
  @app.State('venues') public venues!: Venue[];

  useMFA: boolean = false;

  public login(data: Credentials) {
    this.doLogin(data)
      .then(() => {
        this.$router.push({ name: 'venue.index' });
        this.notifySuccess('auth.notification.login');
      })
      .catch(this.handleLoginError);
  }

  handleLoginError(err: Error | AxiosError) {
    if (!isAxiosError(err)) {
      throw err;
    }
    // @ts-ignore
    const errorName = (err as AxiosError).response?.data.name;
    if (errorName === 'MFAError') {
      if (this.useMFA) {
        return this.notifyError('notification.mfa');
      }
      this.useMFA = true;
      return;
    }
    if (errorName === 'InvalidCredentialsError') {
      return this.notifyError('notification.401');
    }
    if (errorName === 'AccountNotConfirmedError') {
      return this.notifyError('notification.accountNotConfirmedError');
    }
    throw err;
  }
}
