
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Credentials } from '@/interfaces/auth/Credentials';
import { FormItem } from '@/interfaces/components/FormItem';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';

@Component({
  components: { VFormBuilder },
})
export default class LoginForm extends Vue {
  @Prop({ type: Boolean }) useMfa!: boolean;

  public $refs!: {
    form: any;
  };

  get formItems() {
    const items = [
      { name: 'email', type: InputType.Text, label: 'auth.form.email', rules: 'required|email' },
      {
        name: 'password',
        type: InputType.Password,
        label: 'auth.form.password',
        rules: 'required',
        onEnter: this.submit,
      },
    ];
    if (this.useMfa) {
      items.push({
        name: 'code',
        type: InputType.Text,
        label: 'auth.form.otp',
        rules: 'required',
        onEnter: this.submit,
      });
    }
    return items;
  }

  public form: Credentials = {
    email: '',
    password: '',
  };

  public submit() {
    this.$refs.form.validate().then((result: boolean) => {
      if (result) {
        this.$emit('success', this.$refs.form.form);
      }
    });
  }
}
