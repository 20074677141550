export default [
  { text: '', value: 'data-table-expand', default: true },
  {
    text: 'stats.table.provider',
    value: 'logoProvider',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.order',
    value: 'code',
    default: true,
    align: 'left',
    type: 'slot',
  },
  {
    text: 'stats.table.status',
    value: 'status',
    default: true,
    align: 'left',
    type: 'slot',
  },
  {
    text: 'stats.table.paymentMethod',
    value: 'method',
    default: true,
    align: 'left',
    type: 'slot',
  },
  {
    text: 'stats.table.total',
    value: 'totalPrice',
    default: true,
    align: 'left',
  },
  {
    text: 'stats.table.date',
    value: 'date',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.note',
    value: 'note',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.time',
    value: 'time',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.orderDate',
    value: 'orderDate',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.orderTime',
    value: 'orderTime',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.itemName',
    value: 'item_name',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.loyaltyArticles',
    value: 'loyaltyArticles',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.dndCompanyCode',
    value: 'dndCompanyCode',
    default: true,
    align: 'left',
  },
  {
    text: 'stats.table.table',
    value: 'tableNumber',
    default: true,
    align: 'left',
  },
  {
    text: 'stats.table.retry',
    value: 'retry',
    default: true,
    type: 'slot',
    sortable: false,
  },
  {
    text: 'stats.table.customer',
    value: 'customer',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.type',
    value: 'type',
    default: true,
  },
  {
    text: 'stats.table.subType',
    value: 'subType',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'stats.table.secondaryCode',
    value: 'secondaryCode',
    default: true,
    align: 'left',
  },
];
