
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import VFormBuilder from '../../components/shared/form/VFormBuilder.vue';
import { MFAProvider } from '@/enums/MFAProvider';
import cookie from 'js-cookie';
import MFAApiService from '@/api/http/MFAApiService';
import { AxiosError, AxiosResponse } from 'axios';
import { copyToClipboard, isAxiosError, toDownload } from '@/util/helper';
import { namespace } from 'vuex-class';
import { Permission } from '@/enums/Permission';

const mfa = namespace('mfa');

@Component({
  components: { VFormBuilder, VWrapper },
})
export default class AuthSecurity extends mixins(Notification) {
  setupModalVisible: boolean = false;

  backupModalVisible: boolean = false;

  deactivateModalVisible: boolean = false;

  code: string = '';

  qrCode: string = '';

  backupCodes: string[] = [];

  isMFAEnabled: boolean = false;

  @mfa.Action('openMFAModal') openMFAModal!: any;

  mounted() {
    this.isMFAEnabled = cookie.get('mfa') === MFAProvider.TOTP;
  }

  openSetupModal() {
    const api: MFAApiService = new MFAApiService();
    api.prepareMFA().then((res: AxiosResponse<string>) => {
      this.qrCode = res.data;
      this.setupModalVisible = true;
    });
  }

  enableMFA() {
    const api: MFAApiService = new MFAApiService();
    api
      .enableMFA({ code: this.code })
      .then((res: AxiosResponse<{ backupCodes: string[] }>) => {
        cookie.set('mfa', 'totp');
        this.isMFAEnabled = true;
        this.setupModalVisible = false;
        this.backupCodes = res.data.backupCodes;
        this.backupModalVisible = true;
        this.qrCode = '';
      })
      .catch((err: Error | AxiosError) => {
        // @ts-ignore
        if (isAxiosError(err) && err.response?.data?.name === 'MFASetupError') {
          this.notifyError('auth.notification.invalidMFACode');
          return;
        }
        throw err;
      });
  }

  copyBackupCodes() {
    copyToClipboard(this.backupCodes.join('\n'));
  }

  downloadBackupCodes() {
    toDownload(this.backupCodes.join('\n'), 'smoothr-backup-codes.txt');
  }

  printBackupCodes() {
    const printWindow = window.open('', '_blank');
    printWindow?.document.write('Smoothr Backup Codes <br/><br/>');
    printWindow?.document.write(this.backupCodes.join('<br/>'));
    printWindow?.document.close();
    printWindow?.print();
  }

  showBackupCodes() {
    const action = async (code: string) => {
      const api: MFAApiService = new MFAApiService();
      const res: AxiosResponse<{ backupCodes: string[] }> = await api.listBackupCodes({ code });
      this.backupCodes = res.data.backupCodes;
      this.backupModalVisible = true;
    };
    this.openMFAModal(action);
  }

  regenerateBackupCodes() {
    const action = async (code: string) => {
      const api: MFAApiService = new MFAApiService();
      const res: AxiosResponse<{ backupCodes: string[] }> = await api.regenerateBackupCodes({ code });
      this.backupCodes = res.data.backupCodes;
      this.backupModalVisible = true;
    };
    this.openMFAModal(action);
  }

  disableMFA() {
    const api: MFAApiService = new MFAApiService();
    api
      .disableMFA({ code: this.code })
      .then(() => {
        cookie.remove('mfa');
        this.isMFAEnabled = false;
        this.deactivateModalVisible = false;
        this.code = '';
      })
      .catch((err: Error | AxiosError) => {
        // @ts-ignore
        if (isAxiosError(err) && err.response?.data?.name === 'MFASetupError') {
          this.notifyError('auth.notification.invalidMFACode');
          return;
        }
        throw err;
      });
  }
}
