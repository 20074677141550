
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { ExternalOrderProviders } from '@/interfaces/models/externalOrderProviders/ExternalOrderProviders';
import { Permission } from '@/enums/Permission';
import { Venue } from '@/interfaces/models/Venue';
import { WoltApiService } from '@/api/http/WoltApiService';
import Notification from '@/mixins/Notification';
import { UberEatsApiService } from '@/api/http/UberEatsApiService';
import { JetApiService } from '@/api/http/JetApiService';
import SyncExternalFoodcardsButton from '@/components/article/SyncExternalFoodcardsButton.vue';
import { ExternalOrderProviderVenueMode } from '@/enums/ExternalOrderProviderVenueMode';
import VOpeningHourBundle from '@/components/shared/domain/VOpeningHoursBundle.vue';
import { OpeningHour } from '@/interfaces/models/OpeningHour';
import { ArticleDependencyMode } from '@/enums/ArticleDependencyMode';

@Component({
  components: { SyncExternalFoodcardsButton, VFormBuilder, VOpeningHourBundle },
})
export default class ExternalOrderProvidersForm extends mixins(StackedForm, Notification) {
  @Prop({ type: Object, required: true }) public venue!: Venue;

  public $refs!: {
    generalForm: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
    orderDirectForm: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
    woltForm: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
    uberEatsForm: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
    jetForm: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
    woltOpeningHour: InstanceType<typeof VOpeningHourBundle> & {
      getHours: () => OpeningHour[];
      setHours: (hours: OpeningHour[], overwrite?: boolean) => void;
    };
    uberEatsOpeningHour: InstanceType<typeof VOpeningHourBundle> & {
      getHours: () => OpeningHour[];
      setHours: (hours: OpeningHour[], overwrite?: boolean) => void;
    };
    uberEatsDeliverygHour: InstanceType<typeof VOpeningHourBundle> & {
      getHours: () => OpeningHour[];
      setHours: (hours: OpeningHour[], overwrite?: boolean) => void;
    };
  };

  private isEnabled: boolean = !!(this.initialValues
    ? (this.initialValues as Venue['externalOrderProviders'])?.useExternalProviders
    : false);

  private isWoltEnabled: boolean = !!(this.initialValues
    ? (this.initialValues as Venue['externalOrderProviders'])?.wolt?.enabled
    : false);

  private isUberEatsEnabled: boolean = !!(this.initialValues
    ? (this.initialValues as Venue['externalOrderProviders'])?.uberEats?.enabled
    : false);

  private isJetEnabled: boolean = !!(this.initialValues
    ? (this.initialValues as Venue['externalOrderProviders'])?.jet?.enabled
    : false);

  uberEatsStoreId: string = ((this.initialValues as Venue['externalOrderProviders'])?.uberEats?.storeId
    ? (this.initialValues as Venue['externalOrderProviders'])?.uberEats?.storeId
    : '') as string;

  isJetCheckoutIntegrationDelayingEnabled: boolean = (this.initialValues as Venue['externalOrderProviders'])?.jet
    ?.checkoutIntegrationDelayingEnabled
    ? !!(this.initialValues as Venue['externalOrderProviders'])?.jet?.checkoutIntegrationDelayingEnabled
    : false;

  public mounted() {
    this.$refs.woltOpeningHour?.setHours(
      (this.initialValues as Venue['externalOrderProviders'])?.wolt?.openingHours || [],
    );
    this.$refs.uberEatsOpeningHour?.setHours(
      (this.initialValues as Venue['externalOrderProviders'])?.uberEats?.openingHours || [],
    );
    this.$refs.uberEatsDeliverygHour?.setHours(
      (this.initialValues as Venue['externalOrderProviders'])?.uberEats?.deliveryHours || [],
    );
  }

  get isWoltSsio() {
    return (this.initialValues as Venue['externalOrderProviders'])?.wolt?.integrationType === 'ssio';
  }

  get generalFormInitialValues() {
    const values: ExternalOrderProviders = { ...this.initialValues } as ExternalOrderProviders;

    return {
      useExternalProviders: values.useExternalProviders,
    };
  }

  get orderDirectFormInitialValues() {
    const values: ExternalOrderProviders = { ...this.initialValues } as ExternalOrderProviders;

    return {
      ...values.orderDirect,
    };
  }

  get woltFormInitialValues() {
    const values: ExternalOrderProviders = { ...this.initialValues } as ExternalOrderProviders;
    return {
      ...values.wolt,
    };
  }

  get uberEatsFormInitialValues(): Partial<ExternalOrderProviders['uberEats']> {
    const values: ExternalOrderProviders = { ...this.initialValues } as ExternalOrderProviders;
    return {
      ...values.uberEats,
    };
  }

  get jetFormInitialValues(): Partial<ExternalOrderProviders['jet']> {
    const values: ExternalOrderProviders = { ...this.initialValues } as ExternalOrderProviders;
    return {
      ...values.jet,
    };
  }

  get generalFormItems() {
    return [
      {
        name: 'useExternalProviders',
        type: InputType.Checkbox,
        label: 'venue.form.externalOrderProviders.useExternalProviders',
        visible: this.$can(Permission.ADMIN), // TODO: special permission,
        change: (val: boolean) => (this.isEnabled = val),
      },
    ];
  }

  get orderDirectFormItems() {
    return [
      {
        name: 'enabled',
        type: InputType.Checkbox,
        label: 'venue.form.externalOrderProviders.orderDirectEnabled',
        visible: this.$can(Permission.ADMIN), // TODO: special permission
      },
      {
        name: 'imprint',
        type: InputType.Textarea,
        label: 'venue.form.externalOrderProviders.orderDirectImprint',
        visible: this.$can(Permission.ADMIN), // TODO: special permission
      },
    ];
  }

  get providerModes() {
    return Object.values(ExternalOrderProviderVenueMode).map((mode: ExternalOrderProviderVenueMode) => ({
      value: mode,
      text: this.$t(`venue.form.externalOrderProviders.providerVenueModes.${mode}`),
    }));
  }

  get articleDependencyModes() {
    return Object.values(ArticleDependencyMode).map((mode: ArticleDependencyMode) => ({
      value: mode,
      text: this.$t(`venue.form.externalOrderProviders.articleDependencyModes.${mode}`),
    }));
  }

  get woltFormItems() {
    const baseItems = [
      {
        name: 'enabled',
        type: InputType.Checkbox,
        label: 'venue.form.externalOrderProviders.woltEnabled',
        visible: this.$can(Permission.ADMIN),
        change: (val: boolean) => (this.isWoltEnabled = val),
      },
      {
        name: 'venue',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.venue',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
        rules: () => (this.isWoltEnabled ? 'required' : ''),
      },
      {
        name: 'preparationTime',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.preparationTime',
        visible: this.isWoltEnabled,
        rules: () => (this.isWoltEnabled ? 'required|integer|min_value:0' : ''),
      },
      {
        name: 'deliveryTime',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.deliveryTime',
        visible: this.isWoltEnabled,
        rules: () => (this.isWoltEnabled ? 'required|integer|min_value:0' : ''),
      },
      {
        name: 'autoAccept',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.autoAccept',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
      },
      {
        name: 'ocuFlowEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.ocuFlowEnabled',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
      },
      {
        name: 'autoSyncEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.autoSyncEnabled',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
      },
      {
        name: 'ignorePosStatusEvents',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.ignorePosStatusEvents',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
      },
      {
        name: 'dispatchMode',
        type: InputType.Select,
        default: ExternalOrderProviderVenueMode.PROVIDER_DELIVERY,
        items: this.providerModes,
        label: 'venue.form.externalOrderProviders.dispatchMode',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
      },
      {
        name: 'articleDependencyMode',
        type: InputType.Select,
        default: ArticleDependencyMode.IGNORE_ARTICLE,
        items: this.articleDependencyModes,
        label: 'venue.form.externalOrderProviders.articleDependencyMode',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
      },
    ];

    if (this.isWoltSsio) return baseItems;
    return [
      ...baseItems,
      {
        name: 'orderApiKey',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.orderApiKey',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
        rules: () => (this.isWoltEnabled ? 'required' : ''),
      },
      {
        name: 'username',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.username',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
        rules: () => (this.isWoltEnabled ? 'required' : ''),
      },
      {
        name: 'password',
        type: InputType.Password,
        label: 'venue.form.externalOrderProviders.password',
        visible: this.$can(Permission.ADMIN) && this.isWoltEnabled,
        rules: () => (this.isWoltEnabled ? 'required' : ''),
      },
    ];
  }

  get woltUrl() {
    if (!this.venue?._id) return;
    const redirectUrl = `${process.env.VUE_APP_API_URL}/v1/wolt/oauth`;

    return `${process.env.VUE_APP_WOLT_SSIO_BASE_URL}?client_id=${process.env.VUE_APP_WOLT_CLIENT_ID}&state=${
      this.venue._id
    }&redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  get uberUrl() {
    if (!this.venue?._id) return;
    const redirectUrl = `${process.env.VUE_APP_API_URL}/v1/uber/eats/oauth`;

    return `${process.env.VUE_APP_UBER_OAUTH_BASE_URL}/v2/authorize?client_id=${process.env.VUE_APP_UBER_EATS_CLIENT_ID}&state=${this.venue._id}:${this.uberEatsStoreId}&redirect_url=${redirectUrl}&response_type=code&scope=eats.pos_provisioning`;
  }

  get jetFormItems() {
    return [
      {
        name: 'enabled',
        type: InputType.Checkbox,
        label: 'venue.form.externalOrderProviders.jetEnabled',
        visible: this.$can(Permission.ADMIN),
        change: (val: boolean) => (this.isJetEnabled = val),
      },
      {
        name: 'apiKey',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.apiKey',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled,
        rules: () => (this.isJetEnabled ? 'required' : ''),
      },
      {
        name: 'orderApiKey',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.orderApiKey',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled,
        rules: () => (this.isJetEnabled ? 'required' : ''),
      },
      {
        name: 'autoAccept',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.autoAccept',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled,
      },
      {
        name: 'ocuFlowEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.ocuFlowEnabled',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled,
      },
      {
        name: 'checkoutIntegrationDelayingEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.externalOrderProviders.checkoutIntegrationDelayingEnabled',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled,
        change: (val: boolean) => (this.isJetCheckoutIntegrationDelayingEnabled = val),
      },
      {
        name: 'preparationTime',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.preparationTime',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled && this.isJetCheckoutIntegrationDelayingEnabled,
        rules: () => (this.isJetEnabled ? 'integer|min_value:0' : ''),
      },
      {
        name: 'deliveryTime',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.deliveryTime',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled && this.isJetCheckoutIntegrationDelayingEnabled,
        rules: () => (this.isJetEnabled ? 'integer|min_value:0' : ''),
      },
      {
        name: 'autoSyncEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.autoSyncEnabled',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled,
      },
      {
        name: 'dispatchMode',
        type: InputType.Select,
        default: ExternalOrderProviderVenueMode.PROVIDER_DELIVERY,
        items: this.providerModes,
        label: 'venue.form.externalOrderProviders.dispatchMode',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled,
      },
      {
        name: 'articleDependencyMode',
        type: InputType.Select,
        default: ArticleDependencyMode.IGNORE_ARTICLE,
        items: this.articleDependencyModes,
        label: 'venue.form.externalOrderProviders.articleDependencyMode',
        visible: this.$can(Permission.ADMIN) && this.isJetEnabled,
      },
    ];
  }

  get uberEatsFormItems() {
    return [
      {
        name: 'enabled',
        type: InputType.Checkbox,
        label: 'venue.form.externalOrderProviders.uberEatsEnabled',
        visible: this.$can(Permission.ADMIN),
        change: (val: boolean) => (this.isUberEatsEnabled = val),
      },
      {
        name: 'storeId',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.venue',
        visible: this.$can(Permission.ADMIN),
        rules: () => (this.isUberEatsEnabled ? 'required' : ''),
        change: (val: string) => (this.uberEatsStoreId = val),
      },
      {
        name: 'preparationTime',
        type: InputType.Text,
        label: 'venue.form.externalOrderProviders.preparationTime',
        visible: this.$can(Permission.ADMIN) && this.isUberEatsEnabled,
        rules: () => (this.isUberEatsEnabled ? 'required|integer|min_value:0' : ''),
      },
      {
        name: 'autoAccept',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.autoAccept',
        visible: this.$can(Permission.ADMIN) && this.isUberEatsEnabled,
      },
      {
        name: 'ocuFlowEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.ocuFlowEnabled',
        visible: this.$can(Permission.ADMIN) && this.isUberEatsEnabled,
      },
      {
        name: 'autoSyncEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.externalOrderProviders.autoSyncEnabled',
        visible: this.$can(Permission.ADMIN) && this.isUberEatsEnabled,
      },
      {
        name: 'dispatchMode',
        type: InputType.Select,
        default: ExternalOrderProviderVenueMode.PROVIDER_DELIVERY,
        items: this.providerModes,
        label: 'venue.form.externalOrderProviders.dispatchMode',
        visible: this.$can(Permission.ADMIN) && this.isUberEatsEnabled,
      },
      {
        name: 'articleDependencyMode',
        type: InputType.Select,
        default: ArticleDependencyMode.IGNORE_ARTICLE,
        items: this.articleDependencyModes,
        label: 'venue.form.externalOrderProviders.articleDependencyMode',
        visible: this.$can(Permission.ADMIN) && this.isUberEatsEnabled,
      },
    ];
  }

  public getData(): { externalOrderProviders: ExternalOrderProviders } {
    const generalFormData: any = { ...this.$refs.generalForm.form };
    const orderDirectFormData: any = { ...this.$refs.orderDirectForm.form };
    const woltFormData: any = {
      ...this.$refs.woltForm.form,
      integrationType: this.isWoltSsio ? 'ssio' : 'default',
      openingHours: this.$refs.woltOpeningHour?.getHours(),
    };
    const uberEatsFormData: any = {
      ...this.$refs.uberEatsForm.form,
      openingHours: this.$refs.uberEatsOpeningHour?.getHours(),
      deliveryHours: this.$refs.uberEatsDeliverygHour?.getHours(),
    };
    const jetFormData: any = { ...this.$refs.jetForm.form };

    return {
      externalOrderProviders: {
        useExternalProviders: generalFormData.useExternalProviders,
        orderDirect: orderDirectFormData,
        wolt: woltFormData,
        uberEats: uberEatsFormData,
        jet: jetFormData,
      },
    };
  }

  public syncWoltFoodcard() {
    if (!this.venue) return;
    const api: WoltApiService = new WoltApiService();
    return api
      .syncFoodcard({ venue: this.venue._id })
      .then(() => this.notifySuccess('venue.form.externalOrderProviders.foodcardSyncStarted'));
  }

  public syncWoltStatus() {
    if (!this.venue) return;
    const api: WoltApiService = new WoltApiService();
    if (this.venue.isServiceActivated) {
      api.online({ venue: this.venue._id }).then(() => this.notifySuccess('venue.form.externalOrderProviders.online'));
      return;
    }
    api.offline({ venue: this.venue._id }).then(() => this.notifySuccess('venue.form.externalOrderProviders.offline'));
    return;
  }

  public syncWoltOpeningHours() {
    if (!this.venue) return;
    const api: WoltApiService = new WoltApiService();
    api
      .updateOpeningHours({ venue: this.venue._id })
      .then(() => this.notifySuccess('venue.form.externalOrderProviders.openingHoursSynched'));
  }

  public syncUberEatsFoodcard() {
    if (!this.venue) return;
    const api: UberEatsApiService = new UberEatsApiService();
    return api
      .syncFoodcard({ venue: this.venue._id })
      .then(() => this.notifySuccess('venue.form.externalOrderProviders.foodcardSyncStarted'));
  }

  public syncJetFoodcard() {
    if (!this.venue) return;
    const api: JetApiService = new JetApiService();
    api
      .syncFoodcard({ venue: this.venue._id })
      .then(() => this.notifySuccess('venue.form.externalOrderProviders.foodcardSyncStarted'));
  }

  public syncJetOpeningHours() {
    if (!this.venue) return;
    const api: JetApiService = new JetApiService();
    api
      .updateOpeningHours({ venue: this.venue._id })
      .then(() => this.notifySuccess('venue.form.externalOrderProviders.openingHoursSynched'));
  }
}
