import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';

export default class SyncPricesApiService extends ApiService<any> {
  constructor() {
    super('general/venue', 'v1');
  }

  public syncPrices(payload: { venue: string; checkoutIntegration: string; mode: string }): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/checkoutIntegrationSync`, payload);
  }
}
