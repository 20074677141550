
import { Component, Prop } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import { OrderFlavor } from '@/enums/OrderFlovor';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import CustomerGroup from '@/mixins/CustomerGroup';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { ExportType } from '@/enums/ExportType';

@Component({
  components: { VFormBuilder },
})
export default class ExportForm extends mixins(StackedForm, CustomerGroup) {
  @Prop({ type: Boolean, required: false }) public disableFlavor!: boolean;
  @Prop({ type: Boolean, required: false }) public disableDates!: boolean;
  @Prop({ type: Boolean, required: false }) public disableTimePicker!: boolean;
  @Prop({ type: Array, required: false }) public venues!: Venue[];
  @Prop({ type: String, default: '' }) public title!: string;
  @Prop({ type: Boolean, default: false }) public loading!: boolean;
  @Prop({ type: Boolean, default: false }) public disableVenues!: boolean;
  @Prop({ type: Boolean, default: true }) public disableCustomerGroup!: boolean;
  @Prop({ type: Boolean, default: false }) public multipleCustomerGroup!: boolean;
  @Prop({ type: Boolean, default: false }) public multipleVenue!: boolean;

  @Prop({ type: Boolean, default: true }) public disableLabel!: boolean;
  @Prop({ type: Array, default: () => [] }) public labels!: VenueLabel[];
  @Prop({ type: Boolean, required: false }) public withoutTags!: boolean;
  @Prop({ type: Boolean, required: false }) public showExpanded!: boolean;
  @Prop({ type: Boolean, required: false }) public withoutCreatedDates!: boolean;
  @Prop({ type: Boolean, required: false }) public withoutCustomerWithNewsletter!: boolean;
  @Prop({ type: Boolean, required: false }) public withoutGender!: boolean;
  @Prop({ type: Boolean, required: false }) public withoutFilterEmptyByMail!: boolean;
  @Prop({ type: Boolean, required: false }) public withoutTypeExportFile!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public legacyExport!: boolean;

  @Prop({ type: Array, required: false }) public mandatoryOneThose!: string[];
  @Prop({ type: Array, required: false }) public exportType!: string[];
  @Prop({ type: Array, required: false }) public defaultCustomerGroup!: string[];

  public $refs!: {
    venueForm: InstanceType<typeof VFormBuilder> & { form: { venue: string } };
    labelForm: InstanceType<typeof VFormBuilder> & { form: { labels: string[] } };
    customerGroupForm: InstanceType<typeof VFormBuilder> & { form: { customerGroup: string } };
    genderForm: InstanceType<typeof VFormBuilder> & { form: { gender: string } };
    typeExportFile: InstanceType<typeof VFormBuilder> & { form: { typeExportFile: string } };
    flavorForm: InstanceType<typeof VFormBuilder> & { form: { flavor: OrderFlavor } };
    dateForm: InstanceType<typeof VFormBuilder> & { form: { range: { from: string; to: string } } };
    timePickerForm: InstanceType<typeof VFormBuilder> & { range: { from: string; to: string } };
    createdDateForm: InstanceType<typeof VFormBuilder> & { form: { range: { from: string; to: string } } };
    withoutTagsRef: InstanceType<typeof VFormBuilder> & { form: { withoutTags: boolean } };
    showExpandedRef: InstanceType<typeof VFormBuilder> & { form: { showExpanded: boolean } };
    withoutCustomerWithNewsletterRef: InstanceType<typeof VFormBuilder> & {
      form: { subscribedToMailing: boolean };
    };
    withoutFilterEmptyByMailRef: InstanceType<typeof VFormBuilder> & { form: { filterEmptyByMail: boolean } };
  };

  get selection() {
    return {
      venue: this.$refs.venueForm && this.$refs.venueForm.form ? this.$refs.venueForm.form.venue : null,
      flavor: this.$refs.flavorForm && this.$refs.flavorForm.form ? this.$refs.flavorForm.form.flavor : null,
      ...this.$refs.dateForm.form.range,
    };
  }

  public getData() {
    return {
      venue: this.$refs.venueForm && this.$refs.venueForm.form ? this.$refs.venueForm.form.venue : null,
      flavor: this.$refs.flavorForm && this.$refs.flavorForm.form ? this.$refs.flavorForm.form.flavor : null,
      labels: this.$refs.labelForm && this.$refs.labelForm.form ? this.$refs.labelForm.form.label : null,
      customerGroups:
        this.$refs.customerGroupForm && this.$refs.customerGroupForm.form
          ? this.$refs.customerGroupForm.form.customerGroup
          : null,
      ...(this.$refs.dateForm && this.$refs.dateForm.form ? this.$refs.dateForm.form.range : null),
      ...(this.$refs.timePickerForm && this.$refs.timePickerForm.form ? this.$refs.timePickerForm.form.range : null),
      ...this.getCreatedDate(this.$refs.createdDateForm),
      withoutTags:
        this.$refs.withoutTagsRef && this.$refs.withoutTagsRef.form ? this.$refs.withoutTagsRef.form.withoutTags : null,
      expanded: this.$refs.showExpandedRef?.form?.expanded || null,
      ...this.getSubscribedToMailing(this.$refs.withoutCustomerWithNewsletterRef),
      ...this.getFilterEmptyByMail(this.$refs.withoutFilterEmptyByMailRef),
      ...this.getGender(this.$refs.genderForm),
      ...this.getTypeExportFile(this.$refs.typeExportFile),
    };
  }

  public getCreatedDate(form: any) {
    if (form && form.form && form.form.createdRange) {
      return { createdFrom: form.form.createdRange.from, createdTo: form.form.createdRange.to };
    }
    return {};
  }

  public getFilterEmptyByMail(form: any) {
    if (form && form.form && form.form.filterEmptyByMail) {
      return { filterEmptyByMail: form.form.filterEmptyByMail };
    }
    return {};
  }

  public getSubscribedToMailing(form: any) {
    if (form && form.form && form.form.subscribedToMailing) {
      return { subscribedToMailing: form.form.subscribedToMailing };
    }
    return {};
  }

  public getGender(form: any) {
    if (form && form.form && form.form.gender) {
      return { gender: form.form.gender };
    }
    return {};
  }

  public getTypeExportFile(form: any) {
    if (form && form.form && form.form.typeExportFile) {
      return { format: form.form.typeExportFile };
    }
    return {};
  }

  get customerGroupItems() {
    return [
      {
        name: 'customerGroup',
        type: InputType.Select,
        label: 'billing.form.customerGroup',
        items: this.scopedCustomerGroups,
        default: this.defaultCustomerGroup ?? null,
        disabled: !!this.defaultCustomerGroup,
        multiple: this.multipleCustomerGroup,
      },
    ];
  }

  get genderItems() {
    return [
      {
        name: 'gender',
        type: InputType.Select,
        label: 'customer.form.gender',
        items: this.gender,
      },
    ];
  }

  get withoutTagsCheckbox() {
    return [
      {
        name: 'withoutTags',
        type: InputType.Checkbox,
        label: 'billing.form.withoutTags',
      },
    ];
  }

  get showExpandedCheckbox() {
    return [
      {
        name: 'expanded',
        type: InputType.Checkbox,
        label: 'billing.form.showExpanded',
      },
    ];
  }

  get withoutNewsletterCheckbox() {
    return [
      {
        name: 'subscribedToMailing',
        type: InputType.Checkbox,
        label: 'billing.form.subscribedToMailing',
      },
    ];
  }

  get withoutFilterEmptyByMailCheckbox() {
    return [
      {
        name: 'filterEmptyByMail',
        type: InputType.Checkbox,
        label: 'billing.form.filterEmptyByMail',
      },
    ];
  }

  get labelItems() {
    return [
      {
        name: 'label',
        type: InputType.Autocomplete,
        label: 'billing.form.label',
        multiple: true,
        items: this.labels,
        itemValue: '_id',
        itemText: 'name',
      },
    ];
  }

  get venueItems() {
    return [
      {
        name: 'venue',
        type: InputType.Autocomplete,
        label: 'billing.form.venue',
        items: this.venues,
        itemValue: '_id',
        itemText: 'name',
        multiple: this.multipleVenue,
        rules: !this.disableCustomerGroup || !this.disableLabel ? '' : 'required',
      },
    ];
  }

  get dateItems() {
    return [
      {
        name: 'range',
        type: InputType.DateRange,
        label: 'billing.form.range',
        rules: 'required',
      },
    ];
  }

  get timePickerItems() {
    return [
      {
        name: 'range',
        type: InputType.DateRangeTime,
        label: 'billing.form.range',
        rules: 'required',
      },
    ];
  }

  get createdDateItems() {
    return [
      {
        name: 'createdRange',
        type: InputType.DateRange,
        label: 'billing.form.range',
      },
    ];
  }

  get flavors(): string[] {
    return Object.values(OrderFlavor);
  }

  get flavorItems() {
    return [
      {
        name: 'flavor',
        type: InputType.Select,
        label: 'billing.form.flavor',
        items: this.flavors,
      },
    ];
  }

  get typeExportItems() {
    return [
      {
        name: 'typeExportFile',
        type: InputType.Select,
        label: 'billing.form.typeExportFile',
        items: this.typeFile,
      },
    ];
  }

  get typeFile() {
    const items = [
      { text: 'Csv', value: ExportType.CSV },
      { text: 'Pdf', value: ExportType.PDF },
      { text: 'Xlsx', value: ExportType.XLSX },
    ];

    return items.filter((item: { text: string; value: string }) => {
      if (this.exportType.includes(item.value)) {
        return item;
      }
    });
  }

  get gender() {
    return [
      { text: this.$t('gender.male'), value: 'male' },
      { text: this.$t('gender.female'), value: 'female' },
      { text: this.$t('gender.other'), value: 'other' },
    ];
  }

  public doExport(emitType: string = 'export') {
    if (!this.$refs.flavorForm && !this.$refs.dateForm && !this.$refs.venueForm && !this.$refs.customerGroupForm) {
      this.$emit(emitType);
      return;
    }
    this.validate().then((res: boolean[]) => {
      if (this.isValid(res)) {
        if (this.mandatoryOneThose) {
          const requiredField = this.mandatoryOneThose.find((item: any) => {
            if (this.getData().hasOwnProperty(item) && this.getData()[item]) {
              return item;
            }
          });
          if (requiredField) {
            this.$emit(emitType, this.getData());
          } else {
            this.$emit('onError', this.mandatoryOneThose);
          }
        } else {
          this.$emit(emitType, this.getData());
        }
      }
    });
  }
}
