import cookie from 'js-cookie';

export function setCookieData<T>(key: string, value: T, expires: number = 1): void {
  const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
  cookie.set(key, stringValue, { expires });
}

export function getCookieData<T>(key: string): T | null {
  const cookieValue = cookie.get(key);
  return cookieValue ? JSON.parse(cookieValue) : null;
}
