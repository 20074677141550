
import { Component, Prop } from 'vue-property-decorator';
import { isAxiosError } from '@/util/helper';
import { mixins } from 'vue-class-component';

import Notification from '@/mixins/Notification';

@Component
export default class MFADialog extends mixins(Notification) {
  @Prop({ type: Boolean, required: true }) public value!: boolean;
  @Prop({ required: true }) public action!: any;

  code: string = '';

  get visibility() {
    return this.value;
  }

  set visibility(val: boolean) {
    if (!val) {
      this.$emit('hide');
    }
  }

  async triggerMFAAction() {
    try {
      await this.action(this.code);
    } catch (err) {
      if (isAxiosError(err) && err.response?.data?.name === 'MFAError') {
        this.notifyError('notification.mfa');
        this.code = '';
        return;
      }
      throw err;
    }

    this.visibility = false;
    this.code = '';
  }
}
