import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './stores/index';
import './assets/scss/app.scss';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css';
import 'tiptap-vuetify/dist/main.css';
import VueAxios from 'vue-axios';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import vuetify from './plugins/vuetify';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import i18n from '@/i18n';
import './validation';
import './filters';
import cookie from 'js-cookie';
import { Loading } from '@/plugins/loading';
import { Confirm } from '@/plugins/confirm';
import { Acl } from '@/plugins/acl';
import { Action } from '@/plugins/action';
import draggable from 'vuedraggable';
import './registerServiceWorker';
import { getJoinedTokenChunk } from '@/util/helper';
// @ts-ignore
Vue.config.productionTip = false;

Vue.component('draggable', draggable);

Vue.use(VueAxios, axios);
Vue.use(Loading);
Vue.use(Acl);
Vue.use(Confirm);
Vue.use(Action);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
});
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  let token: string = cookie.get('token')!;
  // todo - if token the bigger than 4kb
  if (!token && cookie.get('numTokenChunks')) {
    token = getJoinedTokenChunk();
  }

  if (token && config?.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
axios.interceptors.response.use(
  (res: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
    return res;
  },
  (error: AxiosError) => {
    if (
      error.response &&
      !error.request.responseURL.includes('validation') &&
      !error.request.responseURL.includes('payment/invoice') &&
      !error.request.responseURL.includes('data/analytics/')
    ) {
      switch (error.response.status) {
        case 400:
          store.dispatch('notification/error', 'notification.400');
          break;
        case 401:
          // @ts-ignore
          if (error.response?.data?.name === 'MFAError') {
            if (window.location.pathname.includes('login') || window.location.pathname === '/') {
              throw error;
            }
            store.dispatch('notification/error', 'notification.mfa');
            break;
          }
          store.dispatch('notification/error', 'notification.401');
          break;
        case 403:
          store.dispatch('notification/error', 'notification.403');
          break;
        case 404:
          store.dispatch('notification/error', 'notification.404');
          break;
        case 422:
          store.dispatch('notification/error', 'notification.422');
          break;
        case 500:
          store.dispatch('notification/error', 'notification.500');
          break;
        default:
          store.dispatch('notification/error', 'notification.unknown');
          break;
      }
    }

    return Promise.reject(error);
  },
);

store.dispatch('auth/checkAuth');

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
