import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import { Venue } from '@/interfaces/models/Venue';
import { namespace } from 'vuex-class';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import { Article } from '@/interfaces/models/Article';
import Filter from '@/interfaces/api/Filter';

const venue = namespace('venue');
const foodcard = namespace('foodcard');

@Component
export class EditOption extends mixins(Editable) {
  @Prop({ type: Boolean, default: true }) public fetchOptionDetails!: boolean;

  @venue.State('active') public venue!: Venue;

  @venue.Action('triggerRefresh') public refreshVenue!: any;

  @foodcard.State('activeOption') public active!: OptionGroup;
  @foodcard.State('articleNames') public articles!: Array<Partial<Article>>;

  @foodcard.Action('storeOption') public store!: any;
  @foodcard.Action('showOption') public show!: any;
  @foodcard.Action('copyOption') public copyOption!: any;
  @foodcard.Action('resetOption') public resetOption!: any;
  @foodcard.Action('updateOption') public update!: any;
  @foodcard.Action('setActiveOption') public setActive!: any;
  @foodcard.Action('uploadOptionImage') public uploadImage!: any;

  @foodcard.Action('storeOptionArticle') public storeOptionArticle!: any;
  @foodcard.Action('deleteOptionArticle') public deleteOptionArticle!: any;
  @foodcard.Action('hideOptionArticle') public hideOptionArticle!: any;
  @foodcard.Action('makeArticleOptionVisible') public makeArticleOptionVisible!: any;
  @foodcard.Action('deactivateOptionArticle') public deactivateOptionArticle!: any;
  @foodcard.Action('activateOptionArticle') public activateOptionArticle!: any;
  @foodcard.Action('updateOptionArticle') public updateOptionArticle!: any;
  @foodcard.Action('uploadOptionArticleImage') public uploadOptionArticleImage!: any;
  @foodcard.Action('fetchArticleNames') public getArticleNames: any;
  @foodcard.State('optionArticleFilter') public optionArticleFilter!: Filter;

  public optionModalVisible: boolean = false;
  public activeOptionArticle: OptionArticle | null = null;

  get title() {
    return this.editing ? 'option.edit' : 'option.create';
  }

  get option() {
    if (this.active && this.editing) {
      return this.active;
    }

    return null;
  }

  get sortedOptionArticles() {
    if (this.active && this.active.articles) {
      let articles = [...this.active.articles];
      if (this.optionArticleFilter.hasOwnProperty('search') && this.optionArticleFilter.search) {
        articles = articles.filter((obj) =>
          this.$options
            .filters!.localized(obj.name)
            .toLowerCase()
            .includes(this.optionArticleFilter.search.toLowerCase()),
        );
      }

      if (
        this.optionArticleFilter.hasOwnProperty('filterVisible') &&
        this.optionArticleFilter.filterVisible !== undefined
      ) {
        if (this.optionArticleFilter.filterVisible) {
          articles = articles.filter((article) => article.visible);
        } else {
          articles = articles.filter((article) => !article.visible);
        }
      }

      if (this.optionArticleFilter.hasOwnProperty('priceSort')) {
        if (this.optionArticleFilter.priceSort === 'asc') {
          articles.sort((a, b) => a?.price?.$numberDecimal - b?.price?.$numberDecimal);
        } else if (this.optionArticleFilter.priceSort === 'desc') {
          articles.sort((a, b) => b?.price?.$numberDecimal - a?.price?.$numberDecimal);
        }
      }

      return articles;
    }

    return [];
  }

  public async toggleVisibility({ item, value }: { item: OptionArticle; value: boolean }) {
    this.$startLoading('option.article');
    if (value) {
      await this.hideOptionArticle({ venue: this.venue._id, articles: [item._id] });
    } else {
      await this.makeArticleOptionVisible({ venue: this.venue._id, articles: [item._id] });
    }
    this.$stopLoading('option.article');
  }

  public async toggleActivation({ item, value }: { item: OptionArticle; value: boolean }) {
    this.$startLoading('option.article');
    if (value) {
      await this.deactivateOptionArticle({ venue: this.venue._id, articles: [item._id] });
    } else {
      await this.activateOptionArticle({ venue: this.venue._id, articles: [item._id] });
    }
    this.$stopLoading('option.article');
  }

  public async mounted() {
    if (this.fetchOptionDetails) {
      this.$startLoading('venue');
      if (this.editing && this.venue) {
        this.$startLoading('option');
      }
      if (this.editing) {
        await this.show({ id: this.id });
      }
      if (this.venue && this.venue._id) {
        this.$startLoading('article');
        await this.getArticleNames({ venue: this.venue._id });
        this.$stopLoading('article');
      }
      this.$stopLoading('option');
      this.$stopLoading('venue');
    }
  }

  public async getOptionDetails(optionId: string) {
    this.$startLoading('option');
    await this.show({ id: optionId });
    this.$stopLoading('option');
  }
}
