import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Venue } from '@/interfaces/models/Venue';
import { PromoCode } from '@/interfaces/models/PromoCode';

export class PromoCodeApiService extends ApiService<any> {
  constructor() {
    super('general/promoCode');
  }

  public disableCodes(data: { venue: string; promoCodes: string[] }): AxiosPromise<Venue> {
    return axios.patch(`${this.version}/general/venue/${data.venue}/promoCodes/disable`, data);
  }

  public generate(param: { code: Partial<PromoCode>; amount: number }): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/generate`, param);
  }

  public enableCodes(data: { venue: string; promoCodes: string[] }): AxiosPromise<Venue> {
    return axios.patch(`${this.version}/general/venue/${data.venue}/promoCodes/enable`, data);
  }

  public activate(data: { id: string }): AxiosPromise<PromoCode> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/activate`);
  }

  public deactivate(data: { id: string }): AxiosPromise<PromoCode> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/deactivate`);
  }

  public promoCodeCountCsv(lang?: string): AxiosPromise<string> {
    return axios.get(`v1/general/data/promo/count/csv`, { params: { lang } });
  }

  public promoCodeCsv(lang?: string): AxiosPromise<string> {
    return axios.get(`v1/general/data/promo/csv`, { params: { lang } });
  }

  public uploadGiftCards(data: { id: string }, file: FormData): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/gift_cards`, file);
  }

  public uploadImage(data: { id: string; image: FormData }): AxiosPromise<PromoCode> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/photo`, data.image);
  }
}
